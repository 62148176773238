<template>
  <div>
    <Header/>
  
  <div class="flex flex-row w-full account-menu">
    <Sidebar/>


    <div v-if="showToast" class="p-4 text-sm text-gray-800 rounded-lg bg-gray-50 dark:bg-gray-800 dark:text-gray-300 notification" role="alert">
      <template v-if="message !== ''">
        <p>{{message}}</p>
      </template>
    </div>


    <div class="flex-1 overflow-y-auto">
  <div class="xl:container mx-auto py-4 md:py-14 px-4 md:px-8">
    <h1 class="text-black font-bold text-3xl leading-none mb-8 pb-4 border-b-2 border-gray-light max-md:pl-12">Upgrade</h1>
    <h2 class="text-black font-bold text-2xl leading-none mb-6">Upgrade hardware</h2>
    <div class="grid grid-cols-1 lg:grid-cols-4 gap-4 mb-8">
      <div class="2xl:pl-8 mb-8">
        <div class="py-4 sm:py-6 pl-4 flex items-center bg-black rounded shadow-md">
          <svg xmlns="http://www.w3.org/2000/svg" class="text-green mr-3 flex-none" viewBox="0 0 24 24" width="32" height="32">
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path fill="currentColor" d="M13 9h8L11 24v-9H4l9-15v9zm-2 2V7.22L7.532 13H13v4.394L17.263 11H11z"></path>
          </svg>
          <div>
            <h2 class="text-green font-medium text-sm leading-none mb-1 ">Total power</h2>
            <div class="text-base sm:text-xl text-white font-semibold leading-none livepower">{{getPowerUser}}</div>
          </div>
        </div>
        <div class="py-4 sm:py-6 pl-4 border-b-2 border-gray-light flex items-center">
          <svg xmlns="http://www.w3.org/2000/svg" class="text-green mr-3 flex-none" viewBox="0 0 24 24" width="32" height="32">
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path fill="currentColor" d="M12 2a6 6 0 0 1 6 6v1h4v2h-1.167l-.757 9.083a1 1 0 0 1-.996.917H4.92a1 1 0 0 1-.996-.917L3.166 11H2V9h4V8a6 6 0 0 1 6-6zm6.826 9H5.173l.667 8h12.319l.667-8zM13 13v4h-2v-4h2zm-4 0v4H7v-4h2zm8 0v4h-2v-4h2zm-5-9a4 4 0 0 0-3.995 3.8L8 8v1h8V8a4 4 0 0 0-3.8-3.995L12 4z"></path>
          </svg>
          <div>
            <h2 class="text-black font-medium text-sm leading-none mb-1">Purchased power</h2>
            <div class="text-base sm:text-xl text-green-dark font-semibold leading-none"> {{convertPower(purchasedPower)}} </div>
          </div>
        </div>
        <div class="py-4 sm:py-6 pl-4 border-b-2 border-gray-light flex items-center">
          <svg xmlns="http://www.w3.org/2000/svg" class="text-green mr-3 flex-none" viewBox="0 0 24 24" width="32" height="32">
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path fill="currentColor" d="M19.375 15.103A8.001 8.001 0 0 0 8.03 5.053l-.992-1.737A9.996 9.996 0 0 1 17 3.34c4.49 2.592 6.21 8.142 4.117 12.77l1.342.774-4.165 2.214-.165-4.714 1.246.719zM4.625 8.897a8.001 8.001 0 0 0 11.345 10.05l.992 1.737A9.996 9.996 0 0 1 7 20.66C2.51 18.068.79 12.518 2.883 7.89L1.54 7.117l4.165-2.214.165 4.714-1.246-.719zM8.5 14H14a.5.5 0 1 0 0-1h-4a2.5 2.5 0 1 1 0-5h1V7h2v1h2.5v2H10a.5.5 0 1 0 0 1h4a2.5 2.5 0 1 1 0 5h-1v1h-2v-1H8.5v-2z"></path>
          </svg>
          <div>
            <h2 class="text-black font-medium text-sm leading-none mb-1">Hardware costs</h2>
            <div class="text-base sm:text-xl text-green-dark font-semibold leading-none convertbtc"> {{convertToSelectedCurrency(purchasedCost)}} {{selectedCurrency}}</div>
          </div>
        </div>
        <div class="py-4 sm:py-6 pl-4 border-b-2 border-gray-light flex items-center">
          <svg xmlns="http://www.w3.org/2000/svg" class="text-green mr-3 flex-none" viewBox="0 0 24 24" width="32" height="32">
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path fill="currentColor" d="M11 4h10v2H11V4zm0 4h6v2h-6V8zm0 6h10v2H11v-2zm0 4h6v2h-6v-2zM3 4h6v6H3V4zm2 2v2h2V6H5zm-2 8h6v6H3v-6zm2 2v2h2v-2H5z"></path>
          </svg>
          <div>
            <h2 class="text-black font-medium text-sm leading-none mb-1">Upgrades</h2>
            <div class="text-base sm:text-xl text-green-dark font-semibold leading-none"> {{purchasedCount}} </div>
          </div>
        </div>
      </div>

      
      
      <div class="lg:col-span-3 lg:pl-8">
        <div class="grid grid-cols-2 gap-3 mb-6 max-w-sm">
          <button type="button" 
          :class="{ 'border hover:bg-green-dark text-black border-green-dark rounded text-sm bg-green px-4 py-3': buyVia === 'btc', 'border hover:bg-white-dark border-green-dark rounded text-sm bg-white px-4 py-3': buyVia !== 'btc' }"
          @click="setBuyVia('btc')">Buy via BTC</button>
          <button type="button" 
          :class="{ 'border hover:bg-green-dark text-black border-green-dark rounded text-sm bg-green px-4 py-3': buyVia === 'balance', 'border hover:bg-white-dark border-green-dark rounded text-sm bg-white px-4 py-3': buyVia !== 'balance' }"
          @click="setBuyVia('balance')">Buy via ChainMine</button>
        </div>
        <template v-if="buyVia === 'btc'">
        <h2 class="text-black font-medium text-sm leading-none ml-9 mb-3">Buy hardware for:</h2>
        <div class="max-w-sm flex items-center mb-8">
          <svg xmlns="http://www.w3.org/2000/svg" class="text-green mr-4" viewBox="0 0 24 24" width="24" height="24">
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path fill="currentColor" d="M17 16h2V4H9v2h8v10zm0 2v3c0 .552-.45 1-1.007 1H4.007A1.001 1.001 0 0 1 3 21l.003-14c0-.552.45-1 1.007-1H7V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1h-3zM5.003 8L5 20h10V8H5.003zM7 16h4.5a.5.5 0 1 0 0-1h-3a2.5 2.5 0 1 1 0-5H9V9h2v1h2v2H8.5a.5.5 0 1 0 0 1h3a2.5 2.5 0 1 1 0 5H11v1H9v-1H7v-2z"></path>
          </svg>
          <div class="w-full">
            <div class="grid grid-cols-2 mb-1 gap-4 items-center">
              <div class="uppercase text-xs text-gray">Minimum:</div>
              <div class="text-right text-xs text-green-dark">{{convertToSelectedCurrency(18.78)}} {{selectedCurrency}}</div>
            </div>
            <div class="grid grid-cols-2 mb-1 gap-4 items-center">
              <div class="uppercase text-xs text-gray">Maximum:</div>
              <div class="text-right text-xs text-green-dark">{{convertToSelectedCurrency(180000.78)}} {{selectedCurrency}}</div>
            </div>
            <div class="flex shadow-md">
              <input 
                    v-model="inputAmount"
                    @input="updateCalculator"
                    @keypress="restrictToNumbers"
                    class="text-black block bg-white rounded-l px-3 py-1.5 w-full placeholder-green convertbtc" id="deposit" type="number" name="deposit_value" autocomplete="off">
              <select class="setcurrency text-black block bg-white px-3 py-1.5 hover:bg-white-dark w-24 rounded-r cursor-pointer text-sm" title="Choose your currency">
                <option value="usd" selected="">USD</option>
              </select>
            </div>
            <div class="grid grid-cols-2 mt-3 gap-4 items-center">
              <div class="uppercase text-sm text-gray">Miner Hashrate:</div>
              <div class="text-right text-sm font-bold text-green-dark">
                <span id="power">{{minerHashRate}}</span>
              </div>
            </div>
          </div>
        </div>
        <div>
          
          <h2 class="text-black font-medium text-sm leading-none ml-9 mb-3">Send Bitcoins to:</h2>
          <div class="my-3 ml-9">
            <span style="font-family: monospace;" class="text-base">{{addresBtc}}</span>
          </div>
          <div class="my-3 ml-9">
            <a id="qrout" class="inline-block bg-white p-4 rounded shadow" :href="'bitcoin:'+addresBtc" target="_blank" :title="addresBtc">
              <svg 
                v-if="isLoading"
                class="loader animate-spin text-green on" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="22" height="22"><path fill="none" d="M0 0h24v24H0z"></path><path fill="currentColor" d="M12 2a1 1 0 0 1 1 1v3a1 1 0 0 1-2 0V3a1 1 0 0 1 1-1zm0 15a1 1 0 0 1 1 1v3a1 1 0 0 1-2 0v-3a1 1 0 0 1 1-1zm8.66-10a1 1 0 0 1-.366 1.366l-2.598 1.5a1 1 0 1 1-1-1.732l2.598-1.5A1 1 0 0 1 20.66 7zM7.67 14.5a1 1 0 0 1-.366 1.366l-2.598 1.5a1 1 0 1 1-1-1.732l2.598-1.5a1 1 0 0 1 1.366.366zM20.66 17a1 1 0 0 1-1.366.366l-2.598-1.5a1 1 0 0 1 1-1.732l2.598 1.5A1 1 0 0 1 20.66 17zM7.67 9.5a1 1 0 0 1-1.366.366l-2.598-1.5a1 1 0 1 1 1-1.732l2.598 1.5A1 1 0 0 1 7.67 9.5z"></path></svg>
              <qrcode-vue v-else :value="addresBtc" size="156" level="H"></qrcode-vue>
            </a>
            
          </div>
          <div class="ml-9">
            <a class="text-xs ml-2 text-green" :href="'bitcoin:'+addresBtc">Open in app &gt;</a>
          </div>
          <p class="mt-6 leading-relaxed mb-6 max-w-xl text-sm"> Transaction latency depends on your set transaction fees and Bitcoin network load. After confirming your deposit, you will be allocated hardware power. 
            <br> Minimum purchase amount: <strong>{{convertToSelectedCurrency(18.78)}} {{selectedCurrency}}</strong>
            <br> Lower amounts cannot be processed by the system for technical reasons and will be rejected.
          </p>
        </div>
        </template>
        <template v-else>
          <form @submit.prevent="reinvest">
          <h2 class="text-black font-medium text-sm leading-none ml-9 mb-3">Buy hardware for:</h2>
          <div class="max-w-sm flex items-center mb-8">
            <svg xmlns="http://www.w3.org/2000/svg" class="text-green mr-4" viewBox="0 0 24 24" width="24" height="24">
              <path fill="none" d="M0 0h24v24H0z"></path>
              <path fill="currentColor" d="M17 16h2V4H9v2h8v10zm0 2v3c0 .552-.45 1-1.007 1H4.007A1.001 1.001 0 0 1 3 21l.003-14c0-.552.45-1 1.007-1H7V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1h-3zM5.003 8L5 20h10V8H5.003zM7 16h4.5a.5.5 0 1 0 0-1h-3a2.5 2.5 0 1 1 0-5H9V9h2v1h2v2H8.5a.5.5 0 1 0 0 1h3a2.5 2.5 0 1 1 0 5H11v1H9v-1H7v-2z"></path>
            </svg>


            <div class="w-full">
            <div class="grid grid-cols-2 mb-1 gap-4 items-center">
              <div class="uppercase text-xs text-gray">Minimum:</div>
              <div class="text-right text-xs text-green-dark">{{convertToSelectedCurrency(4.58)}} {{selectedCurrency}}</div>
            </div>
            <div class="grid grid-cols-2 mb-1 gap-4 items-center">
              <div class="uppercase text-xs text-gray">Maximum:</div>
              <div class="text-right text-xs text-green-dark">{{convertToSelectedCurrency(180000.78)}} {{selectedCurrency}}</div>
            </div>
            <div class="flex shadow-md">
              <input 
                    v-model="inputReinvestAmount"
                    @input="reinvestCalculator"
                    @keypress="restrictToNumbers"
                    class="text-black block bg-white rounded-l px-3 py-1.5 w-full placeholder-green convertbtc" type="text" name="deposit_value" autocomplete="off">
              <select class="setcurrency text-black block bg-white px-3 py-1.5 hover:bg-white-dark w-24 rounded-r cursor-pointer text-sm" title="Choose your currency">
                <option value="usd" selected="">USD</option>
              </select>
            </div>
            <div class="grid grid-cols-2 mt-3 gap-4 items-center">
              <div class="uppercase text-sm text-gray">Miner Hashrate:</div>
              <div class="text-right text-sm font-bold text-green-dark">
                <span id="power">{{minerReinvestHashRate}} {{hashUnitReinvest}}</span>
              </div>
            </div>
          </div>


          </div>

          <div>
          <button 
          :disabled="isSubmitting"
          class="ml-9 submit bg-black text-white hover:bg-black-dark rounded py-1.5 px-5" 
          type="submit">
            <svg 
            v-if="isSubmitting"
            class="loader animate-spin text-yellow on" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="22" height="22">
              <path fill="none" d="M0 0h24v24H0z"></path>
              <path fill="currentColor" d="M12 2a1 1 0 0 1 1 1v3a1 1 0 0 1-2 0V3a1 1 0 0 1 1-1zm0 15a1 1 0 0 1 1 1v3a1 1 0 0 1-2 0v-3a1 1 0 0 1 1-1zm8.66-10a1 1 0 0 1-.366 1.366l-2.598 1.5a1 1 0 1 1-1-1.732l2.598-1.5A1 1 0 0 1 20.66 7zM7.67 14.5a1 1 0 0 1-.366 1.366l-2.598 1.5a1 1 0 1 1-1-1.732l2.598-1.5a1 1 0 0 1 1.366.366zM20.66 17a1 1 0 0 1-1.366.366l-2.598-1.5a1 1 0 0 1 1-1.732l2.598 1.5A1 1 0 0 1 20.66 17zM7.67 9.5a1 1 0 0 1-1.366.366l-2.598-1.5a1 1 0 1 1 1-1.732l2.598 1.5A1 1 0 0 1 7.67 9.5z"></path>
            </svg>
            <div class="inline-flex items-center justify-center" 
            v-if="!isSubmitting"> Send <svg xmlns="http://www.w3.org/2000/svg" class="ml-1 text-yellow" viewBox="0 0 24 24" width="22" height="22">
                <path fill="none" d="M0 0h24v24H0z"></path>
                <path fill="currentColor" d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z"></path>
              </svg>
            </div>
          </button>
        </div>


          <p class="mt-6 leading-relaxed mb-6 max-w-xl text-sm"> You can buy new power directly with your mined Bitcoins. This method is faster and we will charged directly with your Balance.
          </p>
        </form>
        </template>
      </div>
    

      
    </div>
    <h2 class="text-black font-bold text-2xl leading-none mt-16 mb-8">All your upgrades</h2>
    <div class="overflow-x-auto shadow-lg max-w-full w-full">
      <table class="table-fixed w-248 lg:w-full text-gray text-sm">
        <tbody>
          <tr class="text-left text-base text-white">
            <th class="bg-black font-medium px-4 py-2 rounded-l">Transaction</th>
            <th class="bg-black font-medium px-4 py-2">Method</th>
            <th class="bg-black font-medium px-4 py-2">Status</th>
            <th class="bg-black font-medium px-4 py-2">Power</th>
            <th class="bg-black font-medium px-4 py-2">Costs</th>
            <th class="bg-black font-medium px-4 py-2 rounded-r">Date &amp; Time</th>
          </tr>

          <template v-if="upgradeData.data.length > 0">
            <tr class="bg-white hover:bg-white-dark" v-for="(upgrade, index) in upgradeData.data" :key="index">
                <td class="px-4 border-b border-gray-light py-3 truncate">
                  <template v-if="upgrade.type === 0">
                    Balance Payment
                  </template>
                  <template v-else>
                    <a class="text-green" :href="'https://blockstream.info/tx/' + upgrade.transaction_id" target="_blank">
                        {{ upgrade.transaction_id }}
                    </a>
                  </template>
                </td>
              
              <td class="px-4 border-b border-gray-light py-3">
                {{ upgrade.type === 1 ? 'Bitcoin Payment' : 'Balance Payment' }}
              </td>
              <td class="px-4 border-b border-gray-light py-3">{{ upgrade.status === 1 ? 'Done' : 'pending' }}</td>
              <td class="px-4 border-b border-gray-light py-3">{{ convertPower(upgrade.power)}}</td>
              <td class="px-4 border-b border-gray-light py-3">{{ convertToSelectedCurrency(upgrade.usd) }} {{selectedCurrency}}</td>
              <td class="px-4 border-b border-gray-light py-3">{{ formatDate(upgrade.created_at) }}</td>
            </tr>
          </template>
          <template v-else>
            <tr class="bg-white">
              <td class="px-4 border-b border-gray-light py-3 text-center" colspan="6">(none)</td>
            </tr>
          </template>


        </tbody>
      </table>
    </div>
    <div class="text-center mt-6">

      <vue-pagination   
          v-if="upgradeData.data.length > 0"        
          :pagination="upgradeData"
          @paginate="getUpgrades"
          :offset="4">
      </vue-pagination>

    </div>
  </div>
</div>


  </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Sidebar from '@/views/user/Sidebar.vue'
import QrcodeVue from 'qrcode.vue';// eslint-disable-line
import axios from 'axios';// eslint-disable-line
import { mapState, mapActions, mapGetters } from 'vuex';// eslint-disable-line
import moment from 'moment';
import VuePagination from '@/components/Pagination.vue';
export default {
  name: 'ChainvaultmineUpgradeView',
  components: {
    Header, Sidebar, QrcodeVue, VuePagination
  },
  data() {
    return {
      isSubmitting: false,
      showToast: false,
      message: '',
      coinType: 'BTC',
      addresBtc: '',
      minerHashRate: 0,
      hashUnit: 'TH/s',
      hashUnitReinvest: 'TH/s',
      inputAmount: 18.78,
      userPower: 1,
      buyVia: 'btc',
      isLoading: false,
      inputReinvestAmount: 4.58,
      minerReinvestHashRate: 0,
      upgradeData: {
        data: [],
        total: 0,
        per_page: 2,
        from: 1,
        to: 0,
        current_page: 1
      },
      offset: 4,
      purchasedCount: 0,
      purchasedCost: 0,
      purchasedPower: 0
    };
  },

  mounted() {
    this.getBitcoinAddress()
    this.updateCalculator()
    this.getPurchasedData()
    this.$store.dispatch('user/fetchSaldo');
    this.getUpgrades()
    this.reinvestCalculator()
  },
  computed: {
    ...mapGetters({
          autenticado: 'user/autenticado',
          user: 'user/usuario',
          power: 'user/power',
          balance: 'user/balance',
          wallet: 'user/wallet'
      }),
      ...mapState(['selectedCurrency']),
      ...mapGetters(['convertToSelectedCurrency']),
      getPowerUser(){
        let userPower = this.power; // Este valor sería dinámico, pero por ahora lo dejamos fijo para el ejemplo
          
        let { hashValue, hashUnit } = this.convertToTHS(userPower);
        userPower = parseFloat((hashValue).toFixed(2));
        return `${userPower} ${hashUnit}`;
    }
  },
  methods: {
    reinvestCalculator() {
      const amountInDollars = this.inputReinvestAmount;
      const unitPrice = 4.58; // Valor por defecto
      
      let hashRate = 0;
      hashRate = (amountInDollars / unitPrice).toFixed(2);

      this.hashReinvest = hashRate;

      // Convertir el valor ingresado a TH/s
      let { hashValue, hashUnit } = this.convertToTHS(hashRate);

      //console.log(hashValue);
      if(isNaN(amountInDollars)){
        this.minerReinvestHashRate = 0.00;
      } else {

        this.minerReinvestHashRate = hashValue;
        this.hashUnitReinvest = hashUnit; // Unidad de hash
      }
    },
    reinvest() {
      if (this.isSubmitting) return;
      // Verificar la conexión con el servidor
      if (!navigator.onLine) {
          // Mostrar un mensaje de error si no hay conexión a internet
          this.showToast = true;
          this.message = 'No internet connection available. Please try again later.';
          setTimeout(() => {
              this.showToast = false;
              this.message = '';
          }, 5000);
          return;
      }

      // Validar si el usuario ha ingresado un valor válido
      const amountInDollars = parseFloat(this.inputReinvestAmount);
      if (amountInDollars < 4.58 || isNaN(amountInDollars)) {
          // Mostrar un mensaje de error o realizar alguna acción
          this.showToast = true;
          this.message = 'Invalid Reinvest value';
          setTimeout(()=>{
              this.showToast = false;
              this.message = '';
            },5000);
          return;
      }

      // Verificar si el valor ingresado es un número válido y mayor que cero
      if (isNaN(amountInDollars) || amountInDollars <= 0) {
          // Mostrar un mensaje de error o realizar alguna acción
          this.showToast = true;
          this.message = 'Please enter a valid amount greater than zero';
          setTimeout(() => {
              this.showToast = false;
              this.message = '';
          }, 5000);
          return;
      }

      // Verificar si el monto a reinvertir es mayor que el saldo disponible
      if (amountInDollars > this.balance) {
          // Mostrar un mensaje de error si el monto es mayor que el saldo
          this.showToast = true;
          this.showError = true;
          this.message = 'Insufficient balance';
          setTimeout(() => {
              this.showToast = false;
              this.message = '';
          }, 5000);
          return;
      }


      

      // Crear un objeto con los datos de reinversión
      const reinvestData = {
          usd: amountInDollars,
          hash: this.hashReinvest
      };

      // Hacer la solicitud HTTP al backend
      this.isSubmitting = true; // Mostrar spinner de carga
      axios.post(process.env.VUE_APP_API_URL+'reinvest', reinvestData)
          .then(response => {
              // Manejar la respuesta del backend
              //console.log(response.data);
              this.showToast = true;
              this.message = response.data.message;
              this.isSubmitting = false; // Ocultar spinner de carga
              this.$store.dispatch('user/fetchSaldo');
              this.getUpgrades();
              this.getPurchasedData();
              this.setBuyVia('btc')
              this.inputReinvestAmount = 4.58;
              this.reinvestCalculator();
              setTimeout(()=>{
              this.showToast = false;
              this.message = '';
            },5000);
          })
          .catch(error => {
              // Manejar cualquier error de la solicitud
              if (error.response.status === 422) {
                if (typeof error.response.data.error === 'string') {
                  this.message = error.response.data.error;
                  this.showToast = true;
                  this.isSubmitting = false;
                  setTimeout(()=>{
                    this.showToast = false;
                    this.message = '';
                  },5000);
                } 
              }
          });
    },
    getUpgrades() {
      axios.get(process.env.VUE_APP_API_URL+`getUpgrades?page=${this.upgradeData.current_page}`)
      .then((res)=>{
        this.upgradeData = res.data;
      })
      .catch((error)=>{
        console.log('error getting upgrades data',error.response.data);
      })
    },
    convertPower(amount){
        let userPower = amount; // Este valor sería dinámico, pero por ahora lo dejamos fijo para el ejemplo
          
        let { hashValue, hashUnit } = this.convertToTHS(userPower);
        userPower = parseFloat((hashValue).toFixed(2));
        return `${userPower} ${hashUnit}`;
    },
    formatDate(date) {
      return moment(date).format('MMM DD YYYY, h:mm A');
    },
    setBuyVia(method) {
        this.buyVia = method;
    },
    updateCalculator() {
        const inputAmount = this.inputAmount; // Valor por defecto
        const unitPrice = 4.58; // Valor por defecto
        
        let hashRate = 0;
        
        hashRate = (inputAmount / unitPrice).toFixed(2);
        //hashRate = (unitPrice * inputAmount).toFixed(2);
        
        

        // Convertir el valor ingresado a TH/s
        let { hashValue, hashUnit } = this.convertToTHS(hashRate);

        //console.log(hashValue);
        this.minerHashRate = `${hashValue} ${hashUnit}`;
        
        
        
    },
    convertToTHS(amount) {
        // Convertir a TH/s
        // Puedes ajustar esta función según las unidades que uses
        // GH/s, TH/s, PH/s, EH/s
        // Por ejemplo, si inputAmount es en PH/s, necesitas multiplicar por 1000 para obtener TH/s
        const hashUnits = ['GH/s', 'TH/s', 'PH/s', 'EH/s']; // Unidades de hash
        let index = 1; // Índice de TH/s por defecto
        
        while (amount >= 1000 && index < hashUnits.length - 1) {
            amount /= 1000;
            index++;
        }
        
        return {
            hashValue: amount,
            hashUnit: hashUnits[index]
        };
    },
    restrictToNumbers(event) {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);

        // Permitir solo números y el punto decimal
        if (!/^[0-9.]+$/.test(keyValue)) {
            event.preventDefault();
        }

        // Asegurarse de que solo se permita un punto decimal
        if (keyValue === '.' && event.target.value.includes('.')) {
            event.preventDefault();
        }
    },
    getBitcoinAddress() {
      this.isLoading = true
      axios.get(process.env.VUE_APP_API_URL + 'getBitcoinAddress', {
          params: {
            coinType: this.coinType
          }
      })
      .then((res)=>{
        this.addresBtc = res.data;
        this.isLoading = false
      })
      .catch((error)=>{
        console.log('error getting DepositAddress',error.response.data);
        this.isLoading = false
      })
    },
    getPurchasedData() {
      axios.get(process.env.VUE_APP_API_URL + 'getPurchasedData')
      .then((res)=>{
        //this.totalPower = res.data.power;
        this.purchasedCount = res.data.purchasedCount;
        this.purchasedCost = res.data.purchasedCost;
        this.purchasedPower = res.data.purchasedPower;
      })
      .catch((error)=>{
        console.error("Error fetching Data:", error);
      })
    },
  },
};
</script>