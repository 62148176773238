<template>
  <div>
    <Header/>

    <main>
  <div class="bg-headline bg-no-repeat bg-cover bg-center relative">
    <div class="bg-gradient-to-tr from-black-95 via-black-75 to-black-95">
      <div class="2xl:container mx-auto px-4 py-14 lg:pl-28">
        <h1 class="font-serif font-black text-4xl sm:text-5xl text-white">Affiliate</h1>
      </div>
    </div>
  </div>
  <div class="2xl:container mx-auto px-2 pt-14 lg:pt-28">
    <div class="rounded-2xl bg-white shadow-xl px-10 md:px-14 py-8 md:py-12 mx-auto max-w-screen-lg grid grid-cols-1 md:grid-cols-3 gap-4 sm:bg-gift bg-right bg-cover sm:bg-contain bg-no-repeat">
      <div class="md:col-span-2">
        <div class="text-black text-lg font-medium leading-none">Additional hash power for every customer</div>
        <h2 class="text-black font-serif font-black text-4xl leading-none mt-1 mb-3">Get up to <span class="text-green font-sans">7+2+1%</span> commission </h2>
        <p class="leading-relaxed text-gray">Sign up now and invite new customers with your personal referral link. Receive up to 10% commission in the form of additional hashpower.</p>
      </div>
      <div class="flex md:justify-center items-center">
        <router-link to="/register" class="shadow text-lg bg-green text-black hover:bg-green-dark rounded py-2 px-6 inline-flex items-center justify-center">
          <svg xmlns="http://www.w3.org/2000/svg" class="mr-2" viewBox="0 0 24 24" width="24" height="24">
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path fill="currentColor" d="M4 3h16a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm7 8H7v2h4v4h2v-4h4v-2h-4V7h-2v4z"></path>
          </svg> Sign Up Now </router-link>
      </div>
    </div>
  </div>
  <div class="2xl:container mx-auto px-2 py-14 lg:py-28 grid grid-cols-1 xl:grid-cols-2 gap-10">
    <div class="flex flex-col items-center">
      <div class="text-black text-lg font-medium leading-none text-center">Expand your earnings</div>
      <h2 class="text-black font-serif font-black text-4xl leading-none mt-1 mb-4 text-center">Our <span class="text-green font-sans">3-level</span> referral system </h2>
      <ul class="mt-4 leading-relaxed text-lg font-medium text-gray">
        <li class="block">
          <svg xmlns="http://www.w3.org/2000/svg" class="text-green inline-block mr-2" viewBox="0 0 24 24" width="24" height="24">
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path fill="currentColor" d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"></path>
          </svg> Level 1 – <strong>7%</strong>
        </li>
        <li class="block">
          <svg xmlns="http://www.w3.org/2000/svg" class="text-green inline-block mr-2" viewBox="0 0 24 24" width="24" height="24">
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path fill="currentColor" d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"></path>
          </svg> Level 2 – <strong>2%</strong>
        </li>
        <li class="block">
          <svg xmlns="http://www.w3.org/2000/svg" class="text-green inline-block mr-2" viewBox="0 0 24 24" width="24" height="24">
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path fill="currentColor" d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"></path>
          </svg> Level 3 – <strong>1%</strong>
        </li>
        <li class="block">
          <svg xmlns="http://www.w3.org/2000/svg" class="text-green inline-block mr-2" viewBox="0 0 24 24" width="24" height="24">
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path fill="currentColor" d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"></path>
          </svg> No limits or requirements
        </li>
      </ul>
      <router-link to="/register" class="mt-6 shadow text-lg bg-black text-white hover:bg-black-dark rounded py-2 px-6 inline-flex items-center justify-center">
        <svg xmlns="http://www.w3.org/2000/svg" class="mr-2 text-yellow" viewBox="0 0 24 24" width="24" height="24">
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path fill="currentColor" d="M17.657 14.828l-1.414-1.414L17.657 12A4 4 0 1 0 12 6.343l-1.414 1.414-1.414-1.414 1.414-1.414a6 6 0 0 1 8.485 8.485l-1.414 1.414zm-2.829 2.829l-1.414 1.414a6 6 0 1 1-8.485-8.485l1.414-1.414 1.414 1.414L6.343 12A4 4 0 1 0 12 17.657l1.414-1.414 1.414 1.414zm0-9.9l1.415 1.415-7.071 7.07-1.415-1.414 7.071-7.07zM5.775 2.293l1.932-.518L8.742 5.64l-1.931.518-1.036-3.864zm9.483 16.068l1.931-.518 1.036 3.864-1.932.518-1.035-3.864zM2.293 5.775l3.864 1.036-.518 1.931-3.864-1.035.518-1.932zm16.068 9.483l3.864 1.035-.518 1.932-3.864-1.036.518-1.931z"></path>
        </svg> Get Your Link Today </router-link>
    </div>
    <div class="max-w-xl mx-auto xl:mx-0 xl:max-w-none">
      <div class="text-black text-lg font-medium leading-none">Simple for everyone</div>
      <h2 class="text-black font-serif font-black text-4xl leading-none mt-1 mb-4">The ChainMine Partner Program</h2>
      <p class="leading-relaxed text-gray">Our affiliate program is available to every customer without obligation and can be used directly after registration. Send your personal referral link to friends, acquaintances, in forums or on social media platforms. For every new customer who signs up through your link and purchases hardware power, you will receive a <strong>7% commission (level 1)</strong> on all of his/her total hash power. </p>
      <p class="leading-relaxed text-gray mt-6">Now, if your recruited user follows the same pattern and also recruits more customers, you will get <strong>2% (level 2)</strong> and <strong>1% (level 3)</strong> on their newly recruited customer's hashpower. </p>
    </div>
  </div>
</main>


    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
export default {
  name: 'ChainvaultmineAfilliateView',
  components: {
    Header, Footer
  },
  data() {
    return {
      
    };
  },

  mounted() {
    
  },

  methods: {
    
  },
};
</script>