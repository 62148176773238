<template>
  <div>
    <Header/>
    <main>
      <div class="bg-headline bg-no-repeat bg-cover bg-center relative">
            <div class="bg-gradient-to-tr from-black-95 via-black-75 to-black-95">
                <div class="2xl:container mx-auto px-4 py-14 lg:pl-28">
                    <h1 class="font-serif font-black text-4xl sm:text-5xl text-white">Insights</h1>
                </div>
            </div>
        </div>


        <div class="2xl:container mx-auto px-2 py-14 lg:py-28 grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 gap-6 xl:gap-14">
            <div class="2xl:pl-20">
                <div class="py-4 sm:py-6 pl-4 border-b-2 border-gray-light flex items-center"><svg xmlns="http://www.w3.org/2000/svg" class="text-green mr-3 flex-none" viewBox="0 0 24 24" width="32" height="32"><path fill="none" d="M0 0h24v24H0z" /><path fill="currentColor" d="M4 22a8 8 0 1 1 16 0h-2a6 6 0 1 0-12 0H4zm8-9c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4z" /></svg>
                    <div>
                        <h2 class="text-black font-medium text-sm leading-none mb-1">Total Customers</h2>
                        <div class="text-base sm:text-xl text-green-dark font-semibold leading-none">
                            <ICountUp
                                :delay="delay"
                                :endVal="userCount"
                                :options="options"
                                @ready="onReady"
                            />
                        </div>
                    </div>
                </div>
                <div class="py-4 sm:py-6 pl-4 border-b-2 border-gray-light flex items-center"><svg xmlns="http://www.w3.org/2000/svg" class="text-green mr-3 flex-none" viewBox="0 0 24 24" width="32" height="32"><path fill="none" d="M0 0h24v24H0z" /><path fill="currentColor" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-4H8V8h3V6h2v2h1a2.5 2.5 0 0 1 2 4 2.5 2.5 0 0 1-2 4h-1v2h-2v-2zm-1-3v1h4a.5.5 0 1 0 0-1h-4zm0-3v1h4a.5.5 0 1 0 0-1h-4z" /></svg>
                    <div>
                        <h2 class="text-black font-medium text-sm leading-none mb-1">Total Mined Bitcoins</h2>
                        <div class="text-base sm:text-xl text-green-dark font-semibold leading-none convertbtc shortbtc">{{convertToSelectedCurrency(totalMinedBitcoinsOriginal)}} {{selectedCurrency}}</div>
                    </div>
                </div>
                <div class="py-4 sm:py-6 pl-4 border-b-2 border-gray-light flex items-center"><svg xmlns="http://www.w3.org/2000/svg" class="text-green mr-3 flex-none" viewBox="0 0 24 24" width="32" height="32"><path fill="none" d="M0 0h24v24H0z" /><path fill="currentColor" d="M13 9h8L11 24v-9H4l9-15v9zm-2 2V7.22L7.532 13H13v4.394L17.263 11H11z" /></svg>
                    <div>
                        <h2 class="text-black font-medium text-sm leading-none mb-1">Active Hardware Power</h2>
                        <div class="text-base sm:text-xl text-green-dark font-semibold leading-none">{{totalMiningPower}}</div>
                    </div>
                </div>
                <div class="py-4 sm:py-6 pl-4 border-b-2 border-gray-light flex items-center"><svg xmlns="http://www.w3.org/2000/svg" class="text-green mr-3 flex-none" viewBox="0 0 24 24" width="32" height="32"><path fill="none" d="M0 0L24 0 24 24 0 24z" /><path fill="currentColor" d="M3.929 4.929l1.414 1.414C3.895 7.791 3 9.791 3 12c0 2.21.895 4.21 2.343 5.657L3.93 19.07C2.119 17.261 1 14.761 1 12s1.12-5.261 2.929-7.071zm16.142 0C21.881 6.739 23 9.239 23 12s-1.12 5.262-2.929 7.071l-1.414-1.414C20.105 16.209 21 14.209 21 12s-.895-4.208-2.342-5.656L20.07 4.93zM13 5v6h3l-5 8v-6H8l5-8zM6.757 7.757l1.415 1.415C7.448 9.895 7 10.895 7 12c0 1.105.448 2.105 1.172 2.828l-1.415 1.415C5.672 15.157 5 13.657 5 12c0-1.657.672-3.157 1.757-4.243zm10.487.001C18.329 8.844 19 10.344 19 12c0 1.657-.672 3.157-1.757 4.243l-1.415-1.415C16.552 14.105 17 13.105 17 12c0-1.104-.447-2.104-1.17-2.827l1.414-1.415z" /></svg>
                    <div>
                        <h2 class="text-black font-medium text-sm leading-none mb-1">Total Power Available</h2>
                        <div class="text-base sm:text-xl text-green-dark font-semibold leading-none">70.0 EH/s</div>
                    </div>
                </div>
                <div class="py-4 sm:py-6 pl-4 border-b-2 border-gray-light flex items-center"><svg xmlns="http://www.w3.org/2000/svg" class="text-green mr-3 flex-none" viewBox="0 0 24 24" width="32" height="32"><path fill="none" d="M0 0h24v24H0z" /><path fill="currentColor" d="M5 9a1 1 0 0 1 1 1 6.97 6.97 0 0 1 4.33 1.5h2.17c1.333 0 2.53.58 3.354 1.5H19a5 5 0 0 1 4.516 2.851C21.151 18.972 17.322 21 13 21c-2.79 0-5.15-.603-7.06-1.658A.998.998 0 0 1 5 20H2a1 1 0 0 1-1-1v-9a1 1 0 0 1 1-1h3zm1.001 3L6 17.022l.045.032C7.84 18.314 10.178 19 13 19c3.004 0 5.799-1.156 7.835-3.13l.133-.133-.12-.1a2.994 2.994 0 0 0-1.643-.63L19 15h-2.111c.072.322.111.656.111 1v1H8v-2l6.79-.001-.034-.078a2.501 2.501 0 0 0-2.092-1.416L12.5 13.5H9.57A4.985 4.985 0 0 0 6.002 12zM4 11H3v7h1v-7zm14-6a3 3 0 1 1 0 6 3 3 0 0 1 0-6zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-7-5a3 3 0 1 1 0 6 3 3 0 0 1 0-6zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" /></svg>
                    <div>
                        <h2 class="text-black font-medium text-sm leading-none mb-1">Customer Payouts</h2>
                        <div class="text-base sm:text-xl text-green-dark font-semibold leading-none convertbtc shortbtc">{{convertToSelectedCurrency(totalWithdrawalsOriginal)}} {{selectedCurrency}}</div>
                    </div>
                </div>
                <div class="py-4 sm:py-6 pl-4 border-b-2 border-gray-light flex items-center"><svg xmlns="http://www.w3.org/2000/svg" class="text-green mr-3 flex-none" viewBox="0 0 24 24" width="32" height="32"><path fill="none" d="M0 0h24v24H0z" /><path fill="currentColor" d="M6.382 5.968A8.962 8.962 0 0 1 12 4c2.125 0 4.078.736 5.618 1.968l1.453-1.453 1.414 1.414-1.453 1.453a9 9 0 1 1-14.064 0L3.515 5.93l1.414-1.414 1.453 1.453zM12 20a7 7 0 1 0 0-14 7 7 0 0 0 0 14zm1-8h3l-5 6.5V14H8l5-6.505V12zM8 1h8v2H8V1z" /></svg>
                    <div>
                        <h2 class="text-black font-medium text-sm leading-none mb-1">Last Maintenance</h2>
                        <div class="text-base sm:text-xl text-green-dark font-semibold leading-none">{{days}} days ago</div>
                    </div>
                </div>
            </div>
            <div class="sm:col-span-2 lg:col-span-3">
                <h2 class="text-black font-serif font-black text-3xl leading-none mb-6">Recent customer withdrawals</h2>
                <div class="overflow-x-auto shadow-lg max-w-full w-full">
                    <table class="table-fixed w-248 lg:w-full text-gray text-sm " >
                        <tr class="text-left text-base text-white">
                            <th class="bg-black font-medium px-4 py-2 rounded-l">Transaction</th>
                            <th class="bg-black font-medium px-4 py-2">Amount</th>
                            <th class="bg-black font-medium px-4 py-2">Username</th>
                            <th class="bg-black font-medium px-4 py-2 rounded-r">Date &amp; Time</th>
                        </tr>
                        <template v-if="transactions.length > 0">
                            <tr class="bg-white hover:bg-white-dark" v-for="(transaction, index) in transactions" :key="index">
                                <td class="px-4 border-b border-gray-light py-3 truncate"><a class="text-green" :href="'https://blockstream.info/tx/' + transaction.transaction_id" target="_blank">
                                        {{ transaction.transaction_id.slice(0, 20) }}...
                                    </a>
                                </td>
                                <td class="px-4 border-b border-gray-light py-3 convertbtc">{{ convertToSelectedCurrency(transaction.usd) }} {{selectedCurrency}}</td>
                                <td class="px-4 border-b border-gray-light py-3">{{ transaction.username }}</td>
                                <td class="px-4 border-b border-gray-light py-3">{{ formatDate(transaction.created_at) }}</td>
                            </tr>
                    </template>
                    <template v-else>
                        <tr class="bg-white">
                            <td class="px-4 border-b border-gray-light py-3 text-center" colspan="4">(none)</td>
                        </tr>
                    </template>
                    </table>
                </div>
                <p class="text-gray italic text-sm mt-6"> See the functionality of our automated system in action: live Bitcoin transfers and earnings of our satisfied customers. Exchange your mined Bitcoins for new hardware power or receive them on a Bitcoin wallet of your choice, anytime. Day
                    and night. </p>
            </div>
        </div>


    </main>
    
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import axios from 'axios';
import moment from 'moment';// eslint-disable-line
import ICountUp from 'vue-countup-v2';
import { mapState, mapActions, mapGetters } from 'vuex';// eslint-disable-line
export default {
  name: 'ChainvaultmineInsightsView',
  components: {
    Header, Footer, ICountUp 
  },
  data() {
    return {
        interval: null,
        totalMinedBitcoins: 0,
        totalMinedBitcoinsOriginal: 0,
        totalWithdrawalsOriginal: 0,
        totalWithdrawals: 0,
        userCount: 0,
        previousUserCount: 0,
        totalMiningPower: 0,
        transactions:[],
        delay: 0,
        options: {
            separator: '',
            decimal: '.',
            prefix: '',
            suffix: ''
        },
        days: 0
    };
  },

  mounted() {
    this.insightsData();
  },
  beforeMount() {
    this.interval = setInterval(this.insightsData, 60000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  computed: {
    ...mapState(['selectedCurrency']),
    ...mapGetters(['convertToSelectedCurrency']),
  },
  methods: {
    insightsData() {
      axios.get(process.env.VUE_APP_API_URL+'insightsData')
      .then((res)=>{
        const newUserCount = res.data.userCount;
        this.userCount = newUserCount + 50000;
        this.previousUserCount = this.userCount;
        this.days = res.data.days;
        
        this.totalMinedBitcoins = res.data.totalMinedBitcoins.toFixed(2);
        this.totalMinedBitcoinsOriginal = res.data.totalMinedBitcoins;
        let { hashValue, hashUnit } = this.convertToTHS(res.data.totalMiningPower);
        this.totalMiningPower = `${hashValue} ${hashUnit}`;
        this.totalWithdrawals = res.data.totalWithdrawals.toFixed(2);
        this.totalWithdrawalsOriginal = res.data.totalWithdrawals;
        this.transactions = res.data.lastWithdrawals;
        
      })
      .catch((error)=>{
        console.log('error getting insightsData',error.response.error);
      })
      
    },
    convertToTHS(amount) {
        // Convertir a TH/s
        // Puedes ajustar esta función según las unidades que uses
        // GH/s, TH/s, PH/s, EH/s
        // Por ejemplo, si inputAmount es en PH/s, necesitas multiplicar por 1000 para obtener TH/s
        const hashUnits = ['GH/s', 'TH/s', 'PH/s', 'EH/s']; // Unidades de hash
        let index = 1; // Índice de TH/s por defecto
        
        while (amount >= 1000 && index < hashUnits.length - 1) {
            amount /= 1000;
            index++;
        }
        
        return {
            hashValue: amount.toFixed(2),
            hashUnit: hashUnits[index]
        };
    },
    formatDate(date) {
        return moment(date).format('MMM DD YYYY, h:mm A');
    },
    onReady(instance) {
      if (this.previousUserCount !== this.userCount) {
        instance.update(this.userCount);
      }
    },
  },
};
</script>