<template>
  <div>
    <Header/>
  
  <div class="flex flex-row w-full account-menu">
    <Sidebar/>

    <div class="flex-1 overflow-y-auto">
  <div class="px-4 py-4 mx-auto xl:container md:py-14 md:px-8">
    <h1 class="pb-4 mb-8 text-3xl font-bold leading-none text-black border-b-2 border-gray-light max-md:pl-12">Partnering</h1>
    <h2 class="mb-6 text-2xl font-bold leading-none text-black">Affiliate ad banners & overview</h2>

    <!-- <div class="grid grid-cols-1 gap-4 mb-8 lg:grid-cols-4">
      <div class="mb-8 2xl:pl-8">
        <div class="flex items-center py-4 pl-4 bg-black rounded shadow-md sm:py-6">
          <svg xmlns="http://www.w3.org/2000/svg" class="mr-3 text-green" viewBox="0 0 24 24" width="32" height="32">
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path fill="currentColor" d="M13 9h8L11 24v-9H4l9-15v9zm-2 2V7.22L7.532 13H13v4.394L17.263 11H11z"></path>
        </svg>
          <div>
            <h2 class="mb-1 text-sm font-medium leading-none text-green">Ref. Commission</h2>
            <div class="text-base font-semibold leading-none text-white sm:text-xl convertbtcs"> 0 GH/s</div>
          </div>
        </div>
        <div class="flex items-center py-4 pl-4 border-b-2 sm:py-6 border-gray-light">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" class="mr-3 text-green" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" width="32" height="32">
            <path stroke-linecap="round" stroke-linejoin="round" d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z" />
          </svg>
          <div>
            <h2 class="mb-1 text-sm font-medium leading-none text-black">Referrals</h2>
            <div class="text-base font-semibold leading-none sm:text-xl text-green-dark"> 0 </div>
          </div>
        </div>
      </div>

      <h2 class="mb-3 text-medium font-bold text-black">Choose your banner size</h2>

      <div class="flex items-center max-w-sm">
        <img src="@/assets/images/b2.gif" style="display: block;">
      </div>

    </div> -->

    <div class="grid grid-cols-1 gap-4 mb-8 lg:grid-cols-4">
  <div class="mb-8 2xl:pl-8">
    <!-- Comisiones de Referencia -->
    <div class="flex items-center py-4 pl-4 bg-black rounded shadow-md sm:py-6">
      <svg xmlns="http://www.w3.org/2000/svg" class="mr-3 text-green" viewBox="0 0 24 24" width="32" height="32">
        <path fill="none" d="M0 0h24v24H0z"></path>
        <path fill="currentColor" d="M13 9h8L11 24v-9H4l9-15v9zm-2 2V7.22L7.532 13H13v4.394L17.263 11H11z"></path>
      </svg>
      <div>
        <h2 class="mb-1 text-sm font-medium leading-none text-green">Ref. Commission</h2>
        <div class="text-base font-semibold leading-none text-white sm:text-xl convertbtcs"> {{sumCommissions.toFixed(2)}} {{convertPower(sumCommissions)}}</div>
      </div>
    </div>

    <!-- Referencias -->
    <div class="flex items-center py-4 pl-4 border-b-2 sm:py-6 border-gray-light">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" class="mr-3 text-green" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" width="32" height="32">
        <path stroke-linecap="round" stroke-linejoin="round" d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z" />
      </svg>
      <div>
        <h2 class="mb-1 text-sm font-medium leading-none text-black">Referrals</h2>
        <div class="text-base font-semibold leading-none sm:text-xl text-green-dark"> {{totalReferralsCount}} </div>
      </div>
    </div>
  </div>

  <!-- Selección del tamaño del banner -->
  <div class="lg:col-span-3">
    <h2 class="mb-3 text-medium font-bold text-black">Choose your banner size</h2>
    <select v-model="selectedSize" class="px-3 py-1 text-sm text-white bg-black border border-white mb-2 hover:bg-black-dark focus:bg-black-dark rounded">
      <option value="extra small">Wide small square - 200x200</option>
      <option value="small">Wide medium rectangle - 300x250</option>
      <option value="medium">Wide banner - 468x60</option>
      <option value="large">Wide leaderboard - 728x90</option>
    </select>

    <!-- Mostrar el banner dinámico -->
    <div class="flex items-center">
      <img :src="getBannerSrc" alt="Banner" :style="getBannerStyle">
    </div>


    <!-- Banner Link -->
<div class="mt-4">
  <label for="banner-link" class="block mb-2 font-bold text-black">Banner Link</label>
  <div class="flex items-center">
    <svg xmlns="http://www.w3.org/2000/svg" class="text-green mr-1.5" viewBox="0 0 24 24" width="22" height="22">
      <path fill="none" d="M0 0h24v24H0z"></path>
      <path fill="currentColor" d="M18.364 15.536L16.95 14.12l1.414-1.414a5 5 0 1 0-7.071-7.071L9.879 7.05 8.464 5.636 9.88 4.222a7 7 0 0 1 9.9 9.9l-1.415 1.414zm-2.828 2.828l-1.415 1.414a7 7 0 0 1-9.9-9.9l1.415-1.414L7.05 9.88l-1.414 1.414a5 5 0 1 0 7.071 7.071l1.414-1.414 1.415 1.414zm-.708-10.607l1.415 1.415-7.071 7.07-1.415-1.414 7.071-7.07z"></path>
    </svg>
    <input type="text" id="banner-link" :value="getBannerLink" readonly class="text-black block bg-white shadow-md rounded px-3 py-1.5 w-full max-w-xs placeholder-green">
  </div>
</div>

    <!-- Website Code -->
<div class="mt-4">
  <label for="website-code" class="block mb-2 font-bold text-black">Website Code</label>
  <div class="flex items-center">
    <svg xmlns="http://www.w3.org/2000/svg" class="text-green mr-1.5" viewBox="0 0 24 24" width="22" height="22">
      <path fill="none" d="M0 0h24v24H0z"></path>
      <path fill="currentColor" d="M18.364 15.536L16.95 14.12l1.414-1.414a5 5 0 1 0-7.071-7.071L9.879 7.05 8.464 5.636 9.88 4.222a7 7 0 0 1 9.9 9.9l-1.415 1.414zm-2.828 2.828l-1.415 1.414a7 7 0 0 1-9.9-9.9l1.415-1.414L7.05 9.88l-1.414 1.414a5 5 0 1 0 7.071 7.071l1.414-1.414 1.415 1.414zm-.708-10.607l1.415 1.415-7.071 7.07-1.415-1.414 7.071-7.07z"></path>
    </svg>
    <textarea id="website-code" :value="getWebsiteCode" readonly class="text-black block bg-white shadow-md rounded px-3 py-1.5 w-full max-w-xs placeholder-green"></textarea>
  </div>
</div>

    <!-- Forum Code -->
<div class="mt-4">
  <label for="forum-code" class="block mb-2 font-bold text-black">Forum Code</label>
  <div class="flex items-center">
    <svg xmlns="http://www.w3.org/2000/svg" class="text-green mr-1.5" viewBox="0 0 24 24" width="22" height="22">
      <path fill="none" d="M0 0h24v24H0z"></path>
      <path fill="currentColor" d="M18.364 15.536L16.95 14.12l1.414-1.414a5 5 0 1 0-7.071-7.071L9.879 7.05 8.464 5.636 9.88 4.222a7 7 0 0 1 9.9 9.9l-1.415 1.414zm-2.828 2.828l-1.415 1.414a7 7 0 0 1-9.9-9.9l1.415-1.414L7.05 9.88l-1.414 1.414a5 5 0 1 0 7.071 7.071l1.414-1.414 1.415 1.414zm-.708-10.607l1.415 1.415-7.071 7.07-1.415-1.414 7.071-7.07z"></path>
    </svg>
    <textarea id="forum-code" :value="getForumCode" readonly class="text-black block bg-white shadow-md rounded px-3 py-1.5 w-full max-w-xs placeholder-green"></textarea>
  </div>
</div>



  </div>
</div>



    <h2 class="text-black font-bold text-2xl leading-none mt-16 mb-8">All your referred users</h2>
    <p class="mt-6 leading-relaxed mb-6 max-w-xl font-medium text-sm">Your commission share is calculated based on the hardware power that an invited user purchases.</p>
    <div class="overflow-x-auto shadow-lg max-w-full w-full">
      <table class="table-fixed w-248 lg:w-full text-gray text-sm">
        <tbody>
          <tr class="text-left text-base text-white">
            <th class="bg-black font-medium px-4 py-2 rounded-l">Referred user</th>
            <th class="bg-black font-medium px-4 py-2">Mining power</th>
            <th class="bg-black font-medium px-4 py-2">Level</th>
            <th class="bg-black font-medium px-4 py-2">Commission</th>
            <th class="bg-black font-medium px-4 py-2 rounded-r">Date &amp; Time</th>
          </tr>
          <template v-if="referralsData.data.length > 0">
          <tr class="bg-white hover:bg-white-dark" v-for="(referrals, index) in referralsData.data" :key="index">
            <td class="px-4 border-b border-gray-light py-3">{{ referrals.username }}</td>
            <td class="px-4 border-b border-gray-light py-3">
              {{ referrals.mining_power === 0 ? '0.00 KH/s' : referrals.mining_power.toFixed(2) + ' TH/s' }}
            </td>
            <template v-if="referrals.level === 1">
              <td class="px-4 border-b border-gray-light py-3">
                7.00 % (Level 1)
              </td>
            </template>
            <template v-else-if="referrals.level === 2">
              <td class="px-4 border-b border-gray-light py-3">
                2.00 % (Level 2)
              </td>
            </template>
            <template v-else-if="referrals.level === 3">
              <td class="px-4 border-b border-gray-light py-3">
                1.00 % (Level 3)
              </td>
            </template>
            <td class="px-4 border-b border-gray-light py-3">
              {{ referrals.commission === 0 ? '' : referrals.commission.toFixed(2) + ' TH/s' }}
            </td>
            <td class="px-4 border-b border-gray-light py-3">
              {{ formatDate(referrals.created_at) }}
            </td>
            
          </tr>
        </template>
        <template v-else>
          <tr class="bg-white">
            <td class="px-4 border-b border-gray-light py-3 text-center" colspan="5">(none)</td>
          </tr>
        </template>
          
        </tbody>
      </table>

      

    </div>
    
    <div class="text-center mt-6">

      <vue-pagination  
      v-if="referralsData.data.length > 0"
  :pagination="referralsData"
  @paginate="getReferrals"
  :offset="4">
</vue-pagination>
    </div>


  </div>
  </div>


  </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Sidebar from '@/views/user/Sidebar.vue'
import { mapGetters } from 'vuex';
import axios from 'axios'
import moment from 'moment';// eslint-disable-line
import VuePagination from '@/components/Pagination.vue';
export default {
  name: 'ChainvaultminePartneringView',
  components: {
    Header, Sidebar, VuePagination
  },
  data() {
    return {
      selectedSize: 'medium',
      totalReferralsCount: 0,
      sumCommissions: 0,
      referralsData: {
          data: [],
          total: 0,
          per_page: 10,
          from: 1,
          to: 0,
          current_page: 1
      },
      offset: 4,
    };
  },

  mounted() {
    this.getReferralData()
    this.getReferrals()
  },

  methods: {
    convertPower(amount) {
        const hashUnits = ['GH/s', 'TH/s', 'PH/s', 'EH/s']; // Unidades de hash
        let index = 1; // Índice de TH/s por defecto
        if(amount === 0){
          index = 0;
        }
        
        while (amount >= 1000 && index < hashUnits.length - 1) {
            amount /= 1000;
            index++;
        }
        //console.log(hashUnits[index])
        return hashUnits[index];
    },
    getReferrals(page = 1) {
      //this.isLoadingTable = true;
      console.log(page,'pagina')
      axios.get(process.env.VUE_APP_API_URL+`getPartners?page=${page}`)
      .then((res)=>{
            //console.log(res.data);
            this.referralsData = res.data;
            //this.isLoadingTable = false;
        })
        .catch((error)=>{
        //  this.isLoadingTable = false;
            console.log(error);
        })
    },
    getReferralData() {
      //this.isLoading = true;
      axios.get(process.env.VUE_APP_API_URL + 'getPartnerData')
      .then((res) => {
        const newUserCount = res.data.totalReferralsCount;
        this.totalReferralsCount = newUserCount;
        this.sumCommissions       = res.data.sumCommissions;
        
      })
      .catch((error)=>{
            console.error("Error fetching Data:", error);
      });
    },
    formatDate(date) {
      return moment(date).format('MMM DD YYYY, h:mm A');
    },
  },
  computed: {
    ...mapGetters({
          autenticado: 'user/autenticado',
          user: 'user/usuario',
          balance: 'user/balance',
          power: 'user/power',
          wallet: 'user/wallet'
      }),
    getBannerSrc() {
      switch (this.selectedSize) {
        case 'extra small':
          return require('@/assets/images/b4.gif');
        case 'small':
          return require('@/assets/images/b3.gif');
        case 'medium':
          return require('@/assets/images/b2.gif');
        case 'large':
          return require('@/assets/images/b1.gif');
        default:
          return require('@/assets/images/b2.gif');
      }
    },
    getBannerStyle() {
      switch (this.selectedSize) {
        case 'extra small':
          return { width: '200px', height: '200px' };
        case 'small':
          return { width: '300px', height: '250px' };
        case 'medium':
          return { width: '468px', height: '60px' };
        case 'large':
          return { width: '728px', height: '90px' };
        default:
          return { width: '320px', height: '50px' };
      }
    },
    getImage(){
      switch (this.selectedSize) {
        case 'extra small':
          return 'b4.gif';
        case 'small':
          return 'b3.gif';
        case 'medium':
          return 'b2.gif';
        case 'large':
          return 'b1.gif';
        default:
          return 'b2.gif';
      }
    },
    getBannerLink() {
      return `https://chainmine.cloud/assets/images/banner/${this.getImage}`;
    },
    // Código para insertar en un sitio web
    getWebsiteCode() {
      return `<a href="https://chainmine.cloud/register-referral?ref=${this.user?.user?.referral_code}"><img src="https://chainmine.cloud/assets/images/banner/${this.getImage}" alt="Your Banner" /></a>`;
    },
    // Código para insertar en un foro
    getForumCode() {
      return `[url=https://chainmine.cloud/register-referral?ref=${this.user?.user?.referral_code}][img]https://chainmine.cloud/assets/images/banner/${this.getImage}[/img][/url]`;
    }
  }
};
</script>
<style scoped>
.max-w-xs {
  max-width: 25rem
}
</style>