<template>
    <div>
        <Header/>
  <main>
  <div class="relative bg-center bg-no-repeat bg-cover bg-headline">
    <div class="bg-gradient-to-tr from-black-95 via-black-75 to-black-95">
      <div class="px-4 mx-auto 2xl:container py-14 lg:pl-28">
        <h1 class="font-serif text-4xl font-black text-white sm:text-5xl">Sign in</h1>
      </div>
    </div>
  </div>
  <div 
  v-if="showToast"
  class="p-4 text-sm text-gray-800 rounded-lg bg-gray-50 dark:bg-gray-800 dark:text-gray-300 notification" role="alert">
    {{message}}
  </div>
  <div class="px-2 mx-auto 2xl:container py-14 lg:py-28">
    <form class="max-w-xs mx-auto" @submit.prevent="login">
      <div class="flex items-center mb-6">
        <svg xmlns="http://www.w3.org/2000/svg" class="mr-4 text-green" viewBox="0 0 24 24" width="24" height="24">
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path fill="currentColor" d="M4 22a8 8 0 1 1 16 0h-2a6 6 0 1 0-12 0H4zm8-9c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4z"></path>
        </svg>
        <input class="text-black block bg-white shadow-md rounded px-3 py-1.5 w-full placeholder-green" type="text" v-model="formData.email" name="username" placeholder="Email / Username" spellcheck="false" autocomplete="username" autofocus="" required>
      </div>
      <div class="flex items-center mb-6">
        <svg xmlns="http://www.w3.org/2000/svg" class="mr-4 text-green" viewBox="0 0 24 24" width="24" height="24">
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path fill="currentColor" d="M18 8h2a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h2V7a6 6 0 1 1 12 0v1zM5 10v10h14V10H5zm6 4h2v2h-2v-2zm-4 0h2v2H7v-2zm8 0h2v2h-2v-2zm1-6V7a4 4 0 1 0-8 0v1h8z"></path>
        </svg>
        <input class="text-black block bg-white shadow-md rounded px-3 py-1.5 w-full placeholder-green" id="password" v-model="formData.password" type="password" name="password" placeholder="Password" spellcheck="false" autocomplete="current-password">
      </div>
      <div id="otplogin" class="flex items-center mb-6" hidden="">
        <svg xmlns="http://www.w3.org/2000/svg" class="mr-4 text-green" viewBox="0 0 24 24" width="24" height="24">
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path fill="currentColor" d="M7 4v16h10V4H7zM6 2h12a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1zm6 15a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"></path>
        </svg>
        <input class="text-black block bg-white shadow-md rounded px-3 py-1.5 w-full placeholder-green" type="text" name="otp" pattern="[0-9]{6}" placeholder="2FA Code" spellcheck="false" autocomplete="off">
      </div>
      <div class="flex items-center">
        <router-link class="mr-4 text-sm text-green hover:text-green-dark" to="/forgot-password">Forgot password?</router-link>
        <button 
        :disabled="isSubmitting" 
        class="ml-auto submit bg-black text-white hover:bg-black-dark rounded py-1.5 px-5" type="submit">
          <svg v-if="isSubmitting"
          class="loader animate-spin text-yellow on" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="22" height="22">
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path fill="currentColor" d="M12 2a1 1 0 0 1 1 1v3a1 1 0 0 1-2 0V3a1 1 0 0 1 1-1zm0 15a1 1 0 0 1 1 1v3a1 1 0 0 1-2 0v-3a1 1 0 0 1 1-1zm8.66-10a1 1 0 0 1-.366 1.366l-2.598 1.5a1 1 0 1 1-1-1.732l2.598-1.5A1 1 0 0 1 20.66 7zM7.67 14.5a1 1 0 0 1-.366 1.366l-2.598 1.5a1 1 0 1 1-1-1.732l2.598-1.5a1 1 0 0 1 1.366.366zM20.66 17a1 1 0 0 1-1.366.366l-2.598-1.5a1 1 0 0 1 1-1.732l2.598 1.5A1 1 0 0 1 20.66 17zM7.67 9.5a1 1 0 0 1-1.366.366l-2.598-1.5a1 1 0 1 1 1-1.732l2.598 1.5A1 1 0 0 1 7.67 9.5z"></path>
          </svg>
          <div class="inline-flex items-center justify-center" v-if="!isSubmitting"> Continue <svg xmlns="http://www.w3.org/2000/svg" class="ml-1 text-yellow" viewBox="0 0 24 24" width="22" height="22">
              <path fill="none" d="M0 0h24v24H0z"></path>
              <path fill="currentColor" d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z"></path>
            </svg>
          </div>
        </button>
      </div>
    </form>
    
  </div>
</main>
<Footer/>
</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
export default {
  name: 'ChainvaultmineLogin',
  components: {
    Header, Footer
  },
  data() {
    return {
      isSubmitting: false,
      message: '',
      showToast: false,
      formData: {
          password: '',
          email: ''
      }
    };
  },
  computed: {
    ...mapGetters('user', ['twoFactorEnabled']),
  },
  mounted() {
    
  },

  methods: {
    ...mapActions({
      signIn: 'user/signIn'
    }),
    async login() {
      try {
        if (this.isSubmitting) return;

        // Inicia la animación del botón y deshabilítalo
        this.isSubmitting = true;
        const { twoFactorEnabled } = await this.signIn(this.formData);
        if (twoFactorEnabled) {
          // Redirigir al usuario al formulario 2FA
          this.$router.push({ name: "TwoFactorAuth" });
        } else {
          // Redirigir al usuario al dashboard u otra página
          this.$router.push({ name: "dashboard" });
        }
      } catch (error) {
        if (error.response.status === 422) {
            this.message = error.response.data.error;
            this.showToast = true;
            this.isSubmitting = false;
        }
      }
    },
  },
};
</script>