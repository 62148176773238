import Vue from 'vue'
import Vuex from 'vuex'
import user from "./user";

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
    selectedCurrency: 'USD',
    exchangeRates: {},
  },
  getters: {
    currentCurrency: state => state.selectedCurrency,
    convertToSelectedCurrency: (state) => (amountInUSD) => {
      const btcPriceInUsd = state.exchangeRates['USD']; // Precio de BTC en USD
      const priceInSelectedCurrency = state.exchangeRates[state.selectedCurrency]; // Precio de BTC en la moneda seleccionada

      let convertedAmount;

      if (state.selectedCurrency === 'USD') {
        // Si la moneda seleccionada es USD, retornar el monto en USD
        convertedAmount = amountInUSD;
      } else if (state.selectedCurrency === 'BTC') {
        // Para BTC, dividir el monto en USD por el precio de BTC en USD
        convertedAmount = amountInUSD / btcPriceInUsd;
      } else if (priceInSelectedCurrency) {
        // Para otras monedas, convertir USD a BTC y luego BTC a la moneda seleccionada
        const amountInBtc = amountInUSD / btcPriceInUsd; // Convertir USD a BTC
        convertedAmount = amountInBtc * priceInSelectedCurrency; // Convertir BTC a la moneda seleccionada
      } else {
        // Si no hay tasa disponible, retornar el monto original
        convertedAmount = amountInUSD;
      }

      // Formatear el monto correctamente: 8 decimales para BTC, 2 para otras monedas
      return state.selectedCurrency === 'BTC'
        ? convertedAmount.toFixed(8)
        : convertedAmount.toFixed(2);
    }
  },
  mutations: {
    setCurrency(state, currency) {
      state.selectedCurrency = currency;
    },
    updateExchangeRates(state, rates) {
      // Convertir el array de precios en un objeto de tasas
      state.exchangeRates = rates.reduce((acc, rate) => {
        acc[rate.code] = rate.price;
        return acc;
      }, {});
    },
  },
  actions: {
    updateCurrency({ commit }, currency) {
      commit('setCurrency', currency);
    },
    updateExchangeRates({ commit }, rates) {
      commit('updateExchangeRates', rates);
    },
  },
  modules: {
    user: user
  }
})
