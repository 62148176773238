<template>
  
    <header class="sticky top-0 z-10 shadow-md">
        
      <div 
  v-if="showToast"
  class="p-4 text-sm text-gray-800 rounded-lg bg-gray-50 dark:bg-gray-800 dark:text-gray-300 notification" role="alert">
    {{message}}
  </div>

        <div class="bg-white">
            <div class="relative px-2 py-3 mx-auto leading-none 2xl:container xl:py-4">
                <div class="flex items-center justify-between xl:hidden">
                    <router-link to="/" class="block">
                        <img src="@/assets/images/web-logo.svg" class="w-auto h-5" alt="ChainMine">
                    </router-link>
                    <button class="p-2 text-black rounded-sm hover:bg-white-dark toggle" @click="toggleNavbar" v-click-outside="hide" data-target="mobileMenu" type="button">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"></path><path fill="currentColor" d="M3 4h18v2H3V4zm0 7h12v2H3v-2zm0 7h18v2H3v-2z"></path></svg>
                    </button>
    
                    
                   
                   
                    <div class="absolute inset-x-0 bg-white shadow-md top-full" :style="isNavbarOpen ? 
                    'display:block;' 
                    : 
                    'display:none' ">
                        <nav class="grid grid-cols-2 gap-2">
                            <ul class="flex flex-col items-start pb-2 pl-2 text-sm text-black">
                                <li class="w-36"><router-link class="inline-flex w-full items-center py-1.5 px-2 rounded text-white bg-black hover:bg-black-dark" to="/login"><svg xmlns="http://www.w3.org/2000/svg" class="text-yellow mr-1.5" viewBox="0 0 24 24" width="22" height="22"><path fill="none" d="M0 0h24v24H0z"></path><path fill="currentColor" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-4.987-3.744A7.966 7.966 0 0 0 12 20c1.97 0 3.773-.712 5.167-1.892A6.979 6.979 0 0 0 12.16 16a6.981 6.981 0 0 0-5.147 2.256zM5.616 16.82A8.975 8.975 0 0 1 12.16 14a8.972 8.972 0 0 1 6.362 2.634 8 8 0 1 0-12.906.187zM12 13a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm0-2a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"></path></svg> Sign in </router-link></li>
                                <li class="w-36 mt-0.5"><a class="inline-flex w-full items-center py-1.5 px-2 rounded text-black bg-green hover:bg-green-dark" href="/logout"><svg xmlns="http://www.w3.org/2000/svg" class="mr-1.5" viewBox="0 0 24 24" width="22" height="22"><path fill="none" d="M0 0h24v24H0z"></path><path fill="currentColor" d="M5 22a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v3h-2V4H6v16h12v-2h2v3a1 1 0 0 1-1 1H5zm13-6v-3h-7v-2h7V8l5 4-5 4z"></path></svg> Sign out </a></li>
                            </ul>
                            <ul class="flex flex-col items-end pb-2 pr-4 text-sm text-gray">
                                <li><router-link class="inline-flex items-center py-1.5 px-2 rounded-sm hover:bg-white-dark hover:text-black" to="/"><svg xmlns="http://www.w3.org/2000/svg" class="text-green mr-1.5" viewBox="0 0 24 24" width="22" height="22"><path fill="none" d="M0 0h24v24H0z"></path><path fill="currentColor" d="M19 21H5a1 1 0 0 1-1-1v-9H1l10.327-9.388a1 1 0 0 1 1.346 0L23 11h-3v9a1 1 0 0 1-1 1zm-6-2h5V9.157l-6-5.454-6 5.454V19h5v-6h2v6z"></path></svg> Home </router-link></li>
                                <li class="mt-0.5"><router-link class="inline-flex items-center py-1.5 px-2 rounded-sm hover:bg-white-dark hover:text-black" to="/pricing"><svg xmlns="http://www.w3.org/2000/svg" class="text-green mr-1.5" viewBox="0 0 24 24" width="22" height="22"><path fill="none" d="M0 0h24v24H0z"></path><path fill="currentColor" d="M13 9h8L11 24v-9H4l9-15v9zm-2 2V7.22L7.532 13H13v4.394L17.263 11H11z"></path></svg> Pricing </router-link></li>
                                <li class="mt-0.5"><router-link class="inline-flex items-center py-1.5 px-2 rounded-sm hover:bg-white-dark hover:text-black" to="/insights"><svg xmlns="http://www.w3.org/2000/svg" class="text-green mr-1.5" viewBox="0 0 24 24" width="22" height="22"><path fill="none" d="M0 0L24 0 24 24 0 24z"></path><path fill="currentColor" d="M16 16c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3zM6 12c2.21 0 4 1.79 4 4s-1.79 4-4 4-4-1.79-4-4 1.79-4 4-4zm10 6c-.552 0-1 .448-1 1s.448 1 1 1 1-.448 1-1-.448-1-1-1zM6 14c-1.105 0-2 .895-2 2s.895 2 2 2 2-.895 2-2-.895-2-2-2zm8.5-12C17.538 2 20 4.462 20 7.5S17.538 13 14.5 13 9 10.538 9 7.5 11.462 2 14.5 2zm0 2C12.567 4 11 5.567 11 7.5s1.567 3.5 3.5 3.5S18 9.433 18 7.5 16.433 4 14.5 4z"></path></svg> Insights </router-link></li>
                                <li class="mt-0.5"><router-link class="inline-flex items-center py-1.5 px-2 rounded-sm hover:bg-white-dark hover:text-black" to="/about"><svg xmlns="http://www.w3.org/2000/svg" class="text-green mr-1.5" viewBox="0 0 24 24" width="22" height="22"><path fill="none" d="M0 0h24v24H0z"></path><path fill="currentColor" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zM11 7h2v2h-2V7zm0 4h2v6h-2v-6z"></path></svg> About </router-link></li>
                                <li class="mt-0.5"><router-link class="inline-flex items-center py-1.5 px-2 rounded-sm hover:bg-white-dark hover:text-black" to="/faq"><svg xmlns="http://www.w3.org/2000/svg" class="text-green mr-1.5" viewBox="0 0 24 24" width="22" height="22">
        <path fill="none" d="M0 0h24v24H0z"></path>
        <path fill="currentColor" d="M5.763 17H20V5H4v13.385L5.763 17zm.692 2L2 22.5V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H6.455zM11 14h2v2h-2v-2zM8.567 8.813A3.501 3.501 0 1 1 12 13h-1v-2h1a1.5 1.5 0 1 0-1.471-1.794l-1.962-.393z"></path>
      </svg> FAQs </router-link></li>
                                <li class="mt-0.5"><router-link class="inline-flex items-center py-1.5 px-2 rounded-sm hover:bg-white-dark hover:text-black" to="/affiliate"><svg xmlns="http://www.w3.org/2000/svg" class="text-green mr-1.5" viewBox="0 0 24 24" width="22" height="22"><path fill="none" d="M0 0h24v24H0z"></path><path fill="currentColor" d="M18.364 15.536L16.95 14.12l1.414-1.414a5 5 0 1 0-7.071-7.071L9.879 7.05 8.464 5.636 9.88 4.222a7 7 0 0 1 9.9 9.9l-1.415 1.414zm-2.828 2.828l-1.415 1.414a7 7 0 0 1-9.9-9.9l1.415-1.414L7.05 9.88l-1.414 1.414a5 5 0 1 0 7.071 7.071l1.414-1.414 1.415 1.414zm-.708-10.607l1.415 1.415-7.071 7.07-1.415-1.414 7.071-7.07z"></path></svg> Affiliate </router-link></li>
                            </ul>
                        </nav>
                </div>
            </div>
            <div class="items-center hidden xl:flex"><router-link to="/" class="block"><img src="@/assets/images/web-logo.svg" class="w-auto h-5" alt="Logo"></router-link><svg xmlns="http://www.w3.org/2000/svg" class="text-gray-light ml-4 mr-2.5" width="22" height="22" viewBox="0 0 29.302 25.519"><line x1="28" y2="24" transform="translate(0.651 0.759)" fill="none" stroke="currentColor" stroke-width="2"></line></svg>
                <nav>
                    <ul class="flex text-gray">
  <li>
    <router-link class="inline-flex items-center py-1.5 px-2 rounded-sm hover:bg-white-dark hover:text-black" to="/">
      <svg xmlns="http://www.w3.org/2000/svg" class="text-green mr-1.5" viewBox="0 0 24 24" width="22" height="22">
        <path fill="none" d="M0 0h24v24H0z"></path>
        <path fill="currentColor" d="M19 21H5a1 1 0 0 1-1-1v-9H1l10.327-9.388a1 1 0 0 1 1.346 0L23 11h-3v9a1 1 0 0 1-1 1zm-6-2h5V9.157l-6-5.454-6 5.454V19h5v-6h2v6z"></path>
      </svg> Home </router-link>
  </li>
  <li class="ml-2">
    <router-link class="inline-flex items-center py-1.5 px-2 rounded-sm hover:bg-white-dark hover:text-black" to="/pricing">
      <svg xmlns="http://www.w3.org/2000/svg" class="text-green mr-1.5" viewBox="0 0 24 24" width="22" height="22">
        <path fill="none" d="M0 0h24v24H0z"></path>
        <path fill="currentColor" d="M13 9h8L11 24v-9H4l9-15v9zm-2 2V7.22L7.532 13H13v4.394L17.263 11H11z"></path>
      </svg> Pricing </router-link>
  </li>
  <li class="ml-2">
    <router-link class="inline-flex items-center py-1.5 px-2 rounded-sm hover:bg-white-dark hover:text-black" to="/insights">
      <svg xmlns="http://www.w3.org/2000/svg" class="text-green mr-1.5" viewBox="0 0 24 24" width="22" height="22">
        <path fill="none" d="M0 0L24 0 24 24 0 24z"></path>
        <path fill="currentColor" d="M16 16c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3zM6 12c2.21 0 4 1.79 4 4s-1.79 4-4 4-4-1.79-4-4 1.79-4 4-4zm10 6c-.552 0-1 .448-1 1s.448 1 1 1 1-.448 1-1-.448-1-1-1zM6 14c-1.105 0-2 .895-2 2s.895 2 2 2 2-.895 2-2-.895-2-2-2zm8.5-12C17.538 2 20 4.462 20 7.5S17.538 13 14.5 13 9 10.538 9 7.5 11.462 2 14.5 2zm0 2C12.567 4 11 5.567 11 7.5s1.567 3.5 3.5 3.5S18 9.433 18 7.5 16.433 4 14.5 4z"></path>
      </svg> Insights </router-link>
  </li>
  
  <li class="ml-2">
    <router-link class="inline-flex items-center py-1.5 px-2 rounded-sm hover:bg-white-dark hover:text-black" to="/about">
      <svg xmlns="http://www.w3.org/2000/svg" class="text-green mr-1.5" viewBox="0 0 24 24" width="22" height="22">
        <path fill="none" d="M0 0h24v24H0z"></path>
        <path fill="currentColor" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zM11 7h2v2h-2V7zm0 4h2v6h-2v-6z"></path>
      </svg> About </router-link>
  </li>
  
  <li class="ml-2">
    <router-link class="inline-flex items-center py-1.5 px-2 rounded-sm hover:bg-white-dark hover:text-black" to="/faq">
      <svg xmlns="http://www.w3.org/2000/svg" class="text-green mr-1.5" viewBox="0 0 24 24" width="22" height="22">
        <path fill="none" d="M0 0h24v24H0z"></path>
        <path fill="currentColor" d="M5.763 17H20V5H4v13.385L5.763 17zm.692 2L2 22.5V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H6.455zM11 14h2v2h-2v-2zM8.567 8.813A3.501 3.501 0 1 1 12 13h-1v-2h1a1.5 1.5 0 1 0-1.471-1.794l-1.962-.393z"></path>
      </svg> FAQs </router-link>
  </li>
  
  <li class="ml-2">
    <router-link class="inline-flex items-center py-1.5 px-2 rounded-sm hover:bg-white-dark hover:text-black" to="/affiliate">
      <svg xmlns="http://www.w3.org/2000/svg" class="text-green mr-1.5" viewBox="0 0 24 24" width="22" height="22">
        <path fill="none" d="M0 0h24v24H0z"></path>
        <path fill="currentColor" d="M18.364 15.536L16.95 14.12l1.414-1.414a5 5 0 1 0-7.071-7.071L9.879 7.05 8.464 5.636 9.88 4.222a7 7 0 0 1 9.9 9.9l-1.415 1.414zm-2.828 2.828l-1.415 1.414a7 7 0 0 1-9.9-9.9l1.415-1.414L7.05 9.88l-1.414 1.414a5 5 0 1 0 7.071 7.071l1.414-1.414 1.415 1.414zm-.708-10.607l1.415 1.415-7.071 7.07-1.415-1.414 7.071-7.07z"></path>
      </svg> Affiliate 
    </router-link>
  </li>
</ul>
                </nav>
                <nav class="flex items-center ml-auto"><svg xmlns="http://www.w3.org/2000/svg" class="text-gray-light mr-4 ml-2.5" width="22" height="22" viewBox="0 0 29.302 25.519"><line x1="28" y2="24" transform="translate(0.651 0.759)" fill="none" stroke="currentColor" stroke-width="2"></line></svg>
                  <template v-if="!autenticado">
                    <router-link to="/login" class="bg-black text-white hover:bg-black-dark rounded py-1.5 px-5 inline-flex items-center justify-center no-active-style">
                      <svg xmlns="http://www.w3.org/2000/svg" class="mr-2 text-yellow" viewBox="0 0 24 24" width="22" height="22"><path fill="none" d="M0 0h24v24H0z"></path><path fill="currentColor" d="M4 15h2v5h12V4H6v5H4V3a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-6zm6-4V8l5 4-5 4v-3H2v-2h8z"></path></svg> Sign in </router-link>
                    <router-link to="/register" class="no-active-style2 ml-2 bg-green text-black hover:bg-green-dark rounded py-1.5 px-5 inline-flex items-center justify-center"><svg xmlns="http://www.w3.org/2000/svg" class="mr-2" viewBox="0 0 24 24" width="22" height="22"><path fill="none" d="M0 0h24v24H0z"></path><path fill="currentColor" d="M4 3h16a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm1 2v14h14V5H5zm6 6V7h2v4h4v2h-4v4h-2v-4H7v-2h4z"></path></svg> Sign up </router-link>
                  </template>
                  <template v-else>
                    
                        <svg xmlns="http://www.w3.org/2000/svg" class="text-gray-light mr-4 ml-2.5" width="22" height="22" viewBox="0 0 29.302 25.519">
                          <line x1="28" y2="24" transform="translate(0.651 0.759)" fill="none" stroke="currentColor" stroke-width="2"></line>
                        </svg>
                        <router-link to="/dashboard" class="no-active-style bg-black text-white hover:bg-black-dark rounded py-1.5 px-5 inline-flex items-center justify-center">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="mr-2 text-yellow" width="22" height="22">
                            <path fill="none" d="M0 0h24v24H0z"></path>
                            <path fill="currentColor" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-4.987-3.744A7.966 7.966 0 0 0 12 20c1.97 0 3.773-.712 5.167-1.892A6.979 6.979 0 0 0 12.16 16a6.981 6.981 0 0 0-5.147 2.256zM5.616 16.82A8.975 8.975 0 0 1 12.16 14a8.972 8.972 0 0 1 6.362 2.634 8 8 0 1 0-12.906.187zM12 13a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm0-2a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"></path>
                          </svg>
                          Dashboard 
                        </router-link>
                        <a href="javascript:void(0)" @click="onLogOut" class="no-active-style ml-2 bg-green text-black hover:bg-green-dark rounded py-1.5 px-5 inline-flex items-center justify-center">
                          <svg xmlns="http://www.w3.org/2000/svg" class="mr-2 text-black" viewBox="0 0 24 24" width="22" height="22">
                            <path fill="none" d="M0 0h24v24H0z"></path>
                            <path fill="currentColor" d="M4 15h2v5h12V4H6v5H4V3a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-6zm6-4V8l5 4-5 4v-3H2v-2h8z"></path>
                          </svg>
                          Sign out 
                        </a>
                      
                  </template>
                </nav>
            </div>
        </div>
        </div>
    </header>
  
</template>

<script>
import clickOutside from '../directives/clickOutside';
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'ChainvaultmineHeader',
  data() {
    return {
        isNavbarOpen: false,
        message: '',
        showToast: false,
    };
  },
  computed: {
      isSpecificRoute() {
          const specificRoutes = ['/two-factor-auth', '/extra/rewards', '/extra/lucky', '/extra/mining','/eddie/admin'];
          return specificRoutes.includes(this.$route.path);
      },
      ...mapGetters({
          autenticado: 'user/autenticado',
          user: 'user/usuario'
      }),
  },
  mounted() {
    
  },
  directives: {
    clickOutside,
  },
  methods: {
    ...mapActions({
        logout: 'user/logout'
    }),
    onLogOut() {
        this.logout().then(() => {
          //this.showToast = true;
          //this.message = res.message;
          this.$router.push({ name: "login" });
          /* setTimeout(()=>{
              this.showToast = false;
              this.message = '';
            },3000); */
            
            //console.log(res.message);
        })
    },
    hide() {
      this.isNavbarOpen = false;
    },
    toggleNavbar() {
      this.isNavbarOpen = !this.isNavbarOpen;
    },
    
  },
};
</script>