<template>
  <div class="bg-black">
    <div class="2xl:container mx-auto flex items-center justify-between py-1.5 px-2">
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" class="text-yellow" viewBox="0 0 24 24" width="22" height="22"><path fill="none" d="M0 0h24v24H0z"></path><path fill="currentColor" d="M17 16h2V4H9v2h8v10zm0 2v3c0 .552-.45 1-1.007 1H4.007A1.001 1.001 0 0 1 3 21l.003-14c0-.552.45-1 1.007-1H7V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1h-3zM7 16v2h2v1h2v-1h.5a2.5 2.5 0 1 0 0-5h-3a.5.5 0 1 1 0-1H13v-2h-2V9H9v1h-.5a2.5 2.5 0 1 0 0 5h3a.5.5 0 1 1 0 1H7z"></path></svg>
        </div>
            <ul class="items-center justify-center hidden text-xs text-white lg:flex shrink-0">
                <li v-for="price in bitcoinPrices" :key="price.code" class="px-2.5">
                    1 BTC = {{ price.price }} {{ price.code }}
                </li>
            </ul>
            
            <div>
                <select 
                v-model="selectedCurrency"
                class="w-20 px-3 py-1 text-sm text-white bg-black border border-white rounded-sm cursor-pointer sm:w-24 setcurrency hover:bg-black-dark focus:bg-black-dark" title="Choose your currency"
                @change="handleCurrencyChange">
                    <option value="BTC">BTC</option>
                    <option value="USD">USD</option>
                    <option value="EUR">EUR</option>
                    <option value="GBP">GBP</option>
                    <option value="INR">INR</option>
                    <option value="RUB">RUB</option>
                </select>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import axios from 'axios';
import { mapState, mapActions } from 'vuex';
export default {
  name: 'ChainvaultmineBitcoinPrices',

  data() {
    return {
        bitcoinPrices: [],
        interval: null,
    };
  },
  created() {
    if (!this.bitcoinPrices.length) {
        this.fetchBitcoinPrices();
    }
  },
  beforeMount() {
    this.interval = setInterval(this.fetchBitcoinPrices, 60000);
  },
  mounted() {
    
  },
  computed: {
    selectedCurrency: {
        get() {
            return this.$store.state.selectedCurrency;
        },
        set(value) {
           this.updateCurrency(value);
        }
    },
    
  },
  methods: {
    ...mapActions(['updateCurrency', 'updateExchangeRates']),
    fetchBitcoinPrices() {
        axios.get(process.env.VUE_APP_API_URL + 'prices')
        .then((res)=>{
            this.bitcoinPrices = res.data.bitcoinPrices;
            this.updateExchangeRates(this.bitcoinPrices);
        })
        .catch((error)=>{
            console.error("Error fetching bitcoin prices:", error);
        })
    },
    handleCurrencyChange(event) {
      const selectedCurrency = event.target.value.toUpperCase();
      this.updateCurrency(selectedCurrency);
    },
  },
};
</script>