<template>
  <div>
    <Header/>
    <main>
  <div class="bg-headline bg-no-repeat bg-cover bg-center relative">
    <div class="bg-gradient-to-tr from-black-95 via-black-75 to-black-95">
      <div class="2xl:container mx-auto px-4 py-14 lg:pl-28">
        <h1 class="font-serif font-black text-4xl sm:text-5xl text-white">Sign up</h1>
      </div>
    </div>
  </div>
  <div v-if="showToast" class="p-4 text-sm text-gray-800 rounded-lg bg-gray-50 dark:bg-gray-800 dark:text-gray-300 notification" role="alert">
    <template v-if="errors">
      <ul>
        <template v-for="(fieldErrors, field) in errors">
          <li v-for="error in fieldErrors" :key="error">{{ field }}: {{ error }}</li>
        </template>
      </ul>
    </template>
    <template v-if="message !== ''">
      <p>{{message}}</p>
    </template>
  </div>
  <div class="2xl:container mx-auto px-2 py-14 lg:py-28">
    <form class="max-w-xs mx-auto" @submit.prevent="register">
      <div class="mb-6 flex items-center">
        <svg xmlns="http://www.w3.org/2000/svg" class="text-green mr-4" viewBox="0 0 24 24" width="24" height="24">
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path fill="currentColor" d="M4 22a8 8 0 1 1 16 0h-2a6 6 0 1 0-12 0H4zm8-9c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4z"></path>
        </svg>
        <input class="text-black block bg-white shadow-md rounded px-3 py-1.5 w-full placeholder-green" type="text" v-model="formData.username" name="username" placeholder="New Username" spellcheck="false" autocomplete="username" autofocus="">
      </div>
      <div class="mb-6 flex items-center">
        <svg xmlns="http://www.w3.org/2000/svg" class="text-green mr-4" viewBox="0 0 24 24" width="24" height="24">
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path fill="currentColor" d="M20 12a8 8 0 1 0-3.562 6.657l1.11 1.664A9.953 9.953 0 0 1 12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10v1.5a3.5 3.5 0 0 1-6.396 1.966A5 5 0 1 1 15 8H17v5.5a1.5 1.5 0 0 0 3 0V12zm-8-3a3 3 0 1 0 0 6 3 3 0 0 0 0-6z"></path>
        </svg>
        <input class="text-black block bg-white shadow-md rounded px-3 py-1.5 w-full placeholder-green" v-model="formData.email" type="email" name="email" placeholder="Your Email" spellcheck="false" autocomplete="email">
      </div>
      <div class="mb-6 flex items-center">
        <svg xmlns="http://www.w3.org/2000/svg" class="text-green mr-4" viewBox="0 0 24 24" width="24" height="24">
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path fill="currentColor" d="M18 8h2a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h2V7a6 6 0 1 1 12 0v1zM5 10v10h14V10H5zm6 4h2v2h-2v-2zm-4 0h2v2H7v-2zm8 0h2v2h-2v-2zm1-6V7a4 4 0 1 0-8 0v1h8z"></path>
        </svg>
        <input class="text-black block bg-white shadow-md rounded px-3 py-1.5 w-full placeholder-green" v-model="formData.password" id="password" type="password" name="password" placeholder="New Password" spellcheck="false" autocomplete="new-password">
      </div>
      <div class="flex items-center">
        <button 
            :disabled="isSubmitting" 
            class="ml-auto bg-black text-white hover:bg-black-dark rounded py-1.5 px-5" 
            type="submit">
            <svg v-if="isSubmitting"
            class="loader animate-spin text-yellow on" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="22" height="22"><path fill="none" d="M0 0h24v24H0z"></path><path fill="currentColor" d="M12 2a1 1 0 0 1 1 1v3a1 1 0 0 1-2 0V3a1 1 0 0 1 1-1zm0 15a1 1 0 0 1 1 1v3a1 1 0 0 1-2 0v-3a1 1 0 0 1 1-1zm8.66-10a1 1 0 0 1-.366 1.366l-2.598 1.5a1 1 0 1 1-1-1.732l2.598-1.5A1 1 0 0 1 20.66 7zM7.67 14.5a1 1 0 0 1-.366 1.366l-2.598 1.5a1 1 0 1 1-1-1.732l2.598-1.5a1 1 0 0 1 1.366.366zM20.66 17a1 1 0 0 1-1.366.366l-2.598-1.5a1 1 0 0 1 1-1.732l2.598 1.5A1 1 0 0 1 20.66 17zM7.67 9.5a1 1 0 0 1-1.366.366l-2.598-1.5a1 1 0 1 1 1-1.732l2.598 1.5A1 1 0 0 1 7.67 9.5z"></path></svg>
            <div class="inline-flex items-center justify-center" v-if="!isSubmitting"> 
              Continue 
              <svg xmlns="http://www.w3.org/2000/svg" class="text-yellow ml-1" viewBox="0 0 24 24" width="22" height="22">
                <path fill="none" d="M0 0h24v24H0z"></path>
                <path fill="currentColor" d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z"></path>
              </svg>
            </div>
          </button>
      </div>
      <p class="text-center mt-6 text-xs text-gray"> By clicking Sign Up, you are indicating that you have read and acknowledge our <router-link class="text-green" to="/terms">Terms of Service</router-link>. </p>
      
    </form>
  </div>
  
</main>
<Footer/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import axios from 'axios';
export default {
  name: 'ChainvaultmineRegister',
  components: {
    Header, Footer
  },
  data() {
    return {
      formData: {
        email: '',
        username: '',
        password: '',
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        ipAddress: null
      },
      isSubmitting: false,
      message: '',
      showToast: false,
      showError: false,
      errors: {},
    };
  },

  mounted() {
    this.getIpaddress();
  },

  methods: {
    getIpaddress(){
      axios.get('https://ipinfo.io')
      .then((res)=>{
        this.formData.ipAddress = res.data.ip;
      })
      .catch((error)=>{
        console.log(error)
      })
    },
    register() {
      // Evita el envío si el formulario ya se está enviando
      if (this.isSubmitting) return;

      // Inicia la animación del botón y deshabilítalo
      this.isSubmitting = true;
      this.errors = {};

      axios.post(process.env.VUE_APP_API_URL + 'register', {
        email: this.formData.email,
        username: this.formData.username,
        password: this.formData.password,
        timezone: this.formData.timezone,
        ipAddress: this.formData.ipAddress
      })
      .then(response => {
        // Limpia los campos después de enviar
            this.formData.email = '';
            this.formData.username = '';
            this.formData.password = '';
            this.message = response.data.success;
            this.showToast = true;

            setTimeout(()=>{
              this.showToast = false;
              this.showError = false;
              this.message = '';
              this.$router.push('/login');
            },5000);
            this.isSubmitting = false;
          
          // Si la solicitud fue exitosa, puedes redirigir al usuario a la página de inicio de sesión
          
        })
      .catch(error => {
        // Si la solicitud falla, maneja los errores
        if (error.response.status === 422) {
          if (typeof error.response.data.error === 'string') {
            this.message = error.response.data.error;
            this.showToast = true;
            this.showError = true;
            this.isSubmitting = false;
            setTimeout(()=>{
              this.showToast = false;
              this.showError = false;
              this.message = '';
            },5000);
          } else {
            this.errors = error.response.data.error;
            this.showToast = true;
            this.showError = true;
            this.isSubmitting = false;
            setTimeout(()=>{
              this.showToast = false;
              this.showError = false;
              this.errors = {};
            },5000);
          }
          
        } else {
          // Maneja otros tipos de errores
          console.error(error);
        }
      });
    },
    closeToast() {
      this.showToast = false;
      this.showError = false;
    }
  },
};
</script>