<template>
    <div>
        <Header/>
  <main>
  <div class="relative bg-center bg-no-repeat bg-cover bg-headline">
    <div class="bg-gradient-to-tr from-black-95 via-black-75 to-black-95">
      <div class="px-4 mx-auto 2xl:container py-14 lg:pl-28">
        <h1 class="font-serif text-4xl font-black text-white sm:text-5xl">Two-factor authentication</h1>
      </div>
    </div>
  </div>
  <div 
  v-if="showToast"
  class="p-4 text-sm text-gray-800 rounded-lg bg-gray-50 dark:bg-gray-800 dark:text-gray-300 notification" role="alert">
    {{message}}
  </div>
  <div class="px-2 mx-auto 2xl:container py-14 lg:py-28">
    <form class="max-w-xs mx-auto" @submit.prevent="verifyTwoFactorCode">
      <div id="otplogin" class="flex items-center mb-6">
        <svg xmlns="http://www.w3.org/2000/svg" class="mr-4 text-green" viewBox="0 0 24 24" width="24" height="24">
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path fill="currentColor" d="M7 4v16h10V4H7zM6 2h12a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1zm6 15a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"></path>
        </svg>
        <input 
        v-model="verificationCode"
        @keypress="validateOtpCode"
        class="text-black block bg-white shadow-md rounded px-3 py-1.5 w-full placeholder-green" type="text" name="otp" pattern="[0-9]{6}" placeholder="2FA Code" spellcheck="false" autocomplete="off">
      </div>
      <div class="flex items-center">
        <button 
        :disabled="isSubmitting" 
        class="ml-auto submit bg-black text-white hover:bg-black-dark rounded py-1.5 px-5" type="submit">
          <svg v-if="isSubmitting"
          class="loader animate-spin text-yellow on" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="22" height="22">
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path fill="currentColor" d="M12 2a1 1 0 0 1 1 1v3a1 1 0 0 1-2 0V3a1 1 0 0 1 1-1zm0 15a1 1 0 0 1 1 1v3a1 1 0 0 1-2 0v-3a1 1 0 0 1 1-1zm8.66-10a1 1 0 0 1-.366 1.366l-2.598 1.5a1 1 0 1 1-1-1.732l2.598-1.5A1 1 0 0 1 20.66 7zM7.67 14.5a1 1 0 0 1-.366 1.366l-2.598 1.5a1 1 0 1 1-1-1.732l2.598-1.5a1 1 0 0 1 1.366.366zM20.66 17a1 1 0 0 1-1.366.366l-2.598-1.5a1 1 0 0 1 1-1.732l2.598 1.5A1 1 0 0 1 20.66 17zM7.67 9.5a1 1 0 0 1-1.366.366l-2.598-1.5a1 1 0 1 1 1-1.732l2.598 1.5A1 1 0 0 1 7.67 9.5z"></path>
          </svg>
          <div class="inline-flex items-center justify-center" v-if="!isSubmitting"> Continue <svg xmlns="http://www.w3.org/2000/svg" class="ml-1 text-yellow" viewBox="0 0 24 24" width="22" height="22">
              <path fill="none" d="M0 0h24v24H0z"></path>
              <path fill="currentColor" d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z"></path>
            </svg>
          </div>
        </button>
      </div>
    </form>
    
  </div>
</main>
<Footer/>
</div>
</template>


<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import { mapActions } from 'vuex';
export default {
  name: 'ChainvaultmineTwoFactor',
  components: {
    Header, Footer
  },
  data() {
    return {
      isSubmitting: false,
      message: '',
      showToast: false,
      verificationCode: ''
    };
  },

  mounted() {
    
  },

  methods: {
    ...mapActions({
      twoFactors:'user/verifyTwoFactorCode'
  }),
  async verifyTwoFactorCode() {
      this.isSubmitting = true;
      const code = this.verificationCode;
      try {
        await this.twoFactors({code});
        this.$router.push({ name: 'dashboard' });
      } catch (error) {
        this.message = error.message;
        this.showToast = true;
        this.isSubmitting = false;
        setTimeout(()=>{
              this.showToast = false;
              this.message = '';
            },5000);
      }
    },
    validateOtpCode(event) {
      // Obtener el valor del input
      //const value = event.target.value;
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);

        // Permitir solo números y el punto decimal
        if (!/^[0-9]+$/.test(keyValue) || this.verificationCode.length >= 6) {
            event.preventDefault();
        }
    },
  },
};
</script>