import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { store } from './store/index'
import './plugins/axios';
import Echo from "laravel-echo";
import Pusher from "pusher-js";


require('./store/subscriber');
Vue.config.productionTip = false
import './assets/css/style.css';


// Obtener el token JWT del almacenamiento local o de alguna otra fuente
const jwtToken = localStorage.getItem('token');

/* window.Pusher = Pusher;

window.Echo = new Echo({
  broadcaster: 'pusher',
  key: process.env.VUE_APP_PUSHER_APP_KEY,
  cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
  encrypted: true,
  authEndpoint: 'https://services.chainmine.cloud/broadcasting/auth',
  auth: {
    headers: {
      Authorization: 'Bearer ' + jwtToken
    },
  },
}); */

window.Pusher = Pusher;

window.Echo = new Echo({
  broadcaster: 'pusher',
  key: process.env.VUE_APP_PUSHER_APP_KEY,
  cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
  forceTLS: true,
  authEndpoint: 'https://services.chainmine.cloud/broadcasting/auth',
  auth: {
    headers: {
      Authorization: 'Bearer ' + jwtToken
    },
  },
});


let app = '';
store.dispatch('user/intento', localStorage.getItem('token')).then(() => {
  if (!app) {
    app = new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#chainmine');
  }
});
