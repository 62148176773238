import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import PricingView from '../views/PricingView.vue'
import InsightsView from '../views/InsightsView.vue'
import AboutView from '../views/AboutView.vue'
import FaqView from '../views/FaqView.vue'
import AfiliateView from '../views/AfilliateView.vue'
import TermsView from '../views/TermsView.vue'
import Login from '../views/auth/Login.vue'
import TwoFactor from '../views/auth/TwoFactor'
import Register from '../views/auth/Register.vue'
import RegisterRef from '../views/auth/RegisterRef.vue'
import Forgot from '../views/auth/Forgot.vue'
import DashboardView from '../views/user/DashboardView.vue'
import UpgradeView from '../views/user/UpgradeView.vue'
import TransferView from '../views/user/TransferView.vue'
import PartneringView from '../views/user/PartneringView.vue'
import SettingsView from '../views/user/SettingsView.vue'
import { store } from '../store'; // Importa tu store de Vuex

Vue.use(VueRouter)

/* const guest = (to, from, next) => {
  if (!localStorage.getItem('token')) {
    return next();
  } else {
    return next('/login');
  }
}; */
/* const guest = (to, from, next) => {
  if (!store.getters['user/autenticado']) {  // Si no está autenticado, continuar
    return next();
  } else {
    return next('/dashboard');  // Si está autenticado, redirigir a dashboard en lugar de login
  }
}; */

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/pricing',
    name: 'pricing',
    component: PricingView
  },
  {
    path: '/insights',
    name: 'insights',
    component: InsightsView
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  },
  {
    path: '/faq',
    name: 'faq',
    component: FaqView
  },
  {
    path: '/affiliate',
    name: 'affiliate',
    component: AfiliateView
  },
  {
    path: '/terms',
    name: 'terms',
    component: TermsView
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: DashboardView,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/get-power',
    name: 'getpower',
    component: UpgradeView,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/payout',
    name: 'payout',
    component: TransferView,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/referral',
    name: 'referral',
    component: PartneringView,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/settings',
    name: 'settings',
    component: SettingsView,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
  },
  {
    path: '/register-referral',
    name: 'RegisterRef',
    component: RegisterRef,
  },
  {
    path: '/two-factor-login',
    name: 'TwoFactorAuth',
    component: TwoFactor,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/forgot-password',
    name: 'forgot',
    component: Forgot,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // eslint-disable-next-line
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
})

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters['user/autenticado'];
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const requiresTwoFactorAuth = store.getters['user/requiresTwoFactorAuth'];

  const guestOnly = ['login', 'register', 'forgot', 'RegisterRef']; // Rutas solo para invitados

  // Si está autenticado e intenta acceder a una ruta solo para invitados, redirigir al dashboard
  if (isAuthenticated && guestOnly.includes(to.name)) {
    next({ name: 'dashboard' });
  }
  // Si la ruta requiere autenticación y no está autenticado, redirigir al login
  else if (requiresAuth && !isAuthenticated) {
    next({ name: 'login' });
  }

  // Si requiere autenticación de dos factores y no ha pasado por ella
  else if (requiresAuth && requiresTwoFactorAuth && to.name !== 'TwoFactorAuth') {
    next({ name: 'TwoFactorAuth' });
  }
  // Continuar la navegación
  else {
    next();
  }

  /* if (requiresAuth && !isAuthenticated) {
    next({ name: 'login' });
  } else if (requiresAuth && requiresTwoFactorAuth && to.name !== 'TwoFactorAuth') {
    next({ name: 'TwoFactorAuth' });
  } else {
    next();
  } */
});

export default router
