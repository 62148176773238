// src/plugins/axios.js
import axios from 'axios';
import { store } from '../store'; // Asegúrate de que este sea el path correcto a tu Vuex store
// eslint-disable-next-line
import router from '@/router'; // Asegúrate de que este sea el path correcto a tu router

// Configuración global de Axios para manejar la expiración del token
axios.interceptors.response.use(
    response => {
        return response;  // Si la respuesta es exitosa, simplemente la devolvemos
    },
    error => {
        //console.log(error, 'error')
        // Verificamos si el error es de token expirado (status 401)
        if (error.response && error.response.status === 401) {
            const errorMessage = error.response.data.message || '';
            //console.log(errorMessage, 'error interceptor')
            // Si el token ha expirado, redirigimos al usuario al login
            if (errorMessage === 'Token has expired' || errorMessage === 'Unauthenticated.') {
                store.dispatch('user/logout');  // Acción para limpiar el estado del usuario
                router.push("/login").catch(() => { });
                // Evitar redirecciones innecesarias
                //if (router.currentRoute.path !== '/login') {
                //router.replace('/login');  // Redirigir al login solo si no está ya en esa ruta
                //router.push('/login');
                //}
                //router.push('/login');  // Redirigir al login
            }
        }

        return Promise.reject(error);
    }
);

axios.interceptors.request.use(function (config) {
    if (config.method === 'get') {
        if (typeof config.params !== 'undefined') {
            config.params = Object.assign(config.params, {
                api_key: '1439d1d5306abcb864f054710879ccef'
            })
        } else {
            config.params = {
                api_key: '1439d1d5306abcb864f054710879ccef'
            }
        }
    }
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});