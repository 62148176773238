<template>
  <div>
    <button @click="toggleSideBar" v-click-outside="hideSideBar" class="absolute flex flex-col items-center justify-start px-3 py-2 text-black bg-white rounded-br shadow-md md:hidden hover:bg-white-dark">
        <svg xmlns="http://www.w3.org/2000/svg" class="mt-0.5" viewBox="0 0 24 24" width="24" height="24">
            <path fill="none" d="M0 0H24V24H0z"></path>
            <path fill="currentColor" d="M21 18v2H3v-2h18zM17.404 3.904L22 8.5l-4.596 4.596-1.414-1.414L19.172 8.5 15.99 5.318l1.414-1.414zM12 11v2H3v-2h9zm0-7v2H3V4h9z"></path>
        </svg>
        <div class="mt-1 text-xs text-black">Menu</div>
    </button>


    <div class="fixed w-56 h-full px-3 bg-white shadow-2xl md:static md:block md:w-72 py-14 md:px-8 md:shadow-none" :class="{ 'hidden': !isSideBarOpen }">
  <button @click="hideSideBar" class="inline-block absolute top-3 right-3 md:hidden p-1.5 rounded-sm hover:bg-white-dark text-black">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
      <path fill="none" d="M0 0h24v24H0z"></path>
      <path fill="currentColor" d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"></path>
    </svg>
  </button>
  <ul>
    <li>
      <router-link class="flex items-center py-1.5 px-4 rounded-sm hover:bg-white-dark hover:text-black text-black" to="/dashboard">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="mr-3 text-green" width="24" height="24">
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path fill="currentColor" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-4.987-3.744A7.966 7.966 0 0 0 12 20c1.97 0 3.773-.712 5.167-1.892A6.979 6.979 0 0 0 12.16 16a6.981 6.981 0 0 0-5.147 2.256zM5.616 16.82A8.975 8.975 0 0 1 12.16 14a8.972 8.972 0 0 1 6.362 2.634 8 8 0 1 0-12.906.187zM12 13a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm0-2a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"></path>
        </svg> Dashboard </router-link>
    </li>
    <li>
      <router-link class="mt-2 flex items-center py-1.5 px-4 rounded-sm hover:bg-white-dark hover:text-black" to="/get-power">
        <svg xmlns="http://www.w3.org/2000/svg" class="mr-3 text-green" viewBox="0 0 24 24" width="24" height="24">
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path fill="currentColor" d="M13 9h8L11 24v-9H4l9-15v9zm-2 2V7.22L7.532 13H13v4.394L17.263 11H11z"></path>
        </svg> Upgrade </router-link>
    </li>
    <li>
      <router-link class="mt-2 flex items-center py-1.5 px-4 rounded-sm hover:bg-white-dark hover:text-black" to="/payout">
        <svg xmlns="http://www.w3.org/2000/svg" class="mr-3 text-green" viewBox="0 0 24 24" width="24" height="24">
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path fill="currentColor" d="M19.375 15.103A8.001 8.001 0 0 0 8.03 5.053l-.992-1.737A9.996 9.996 0 0 1 17 3.34c4.49 2.592 6.21 8.142 4.117 12.77l1.342.774-4.165 2.214-.165-4.714 1.246.719zM4.625 8.897a8.001 8.001 0 0 0 11.345 10.05l.992 1.737A9.996 9.996 0 0 1 7 20.66C2.51 18.068.79 12.518 2.883 7.89L1.54 7.117l4.165-2.214.165 4.714-1.246-.719zM8.5 14H14a.5.5 0 1 0 0-1h-4a2.5 2.5 0 1 1 0-5h1V7h2v1h2.5v2H10a.5.5 0 1 0 0 1h4a2.5 2.5 0 1 1 0 5h-1v1h-2v-1H8.5v-2z"></path>
        </svg> Transfer BTC </router-link>
    </li>
    <li>
      <router-link class="mt-2 flex items-center py-1.5 px-4 rounded-sm hover:bg-white-dark hover:text-black" to="/referral">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" class="mr-3 text-green" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" width="24" height="24">
  <path stroke-linecap="round" stroke-linejoin="round" d="M18 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0ZM3 19.235v-.11a6.375 6.375 0 0 1 12.75 0v.109A12.318 12.318 0 0 1 9.374 21c-2.331 0-4.512-.645-6.374-1.766Z" />
</svg>

        Partnering </router-link>
    </li>
    <li>
      <router-link class="mt-2 flex items-center py-1.5 px-4 rounded-sm hover:bg-white-dark hover:text-black" to="/settings">
        <svg xmlns="http://www.w3.org/2000/svg" class="mr-3 text-green" viewBox="0 0 24 24" width="24" height="24">
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path fill="currentColor" d="M3.34 17a10.018 10.018 0 0 1-.978-2.326 3 3 0 0 0 .002-5.347A9.99 9.99 0 0 1 4.865 4.99a3 3 0 0 0 4.631-2.674 9.99 9.99 0 0 1 5.007.002 3 3 0 0 0 4.632 2.672c.579.59 1.093 1.261 1.525 2.01.433.749.757 1.53.978 2.326a3 3 0 0 0-.002 5.347 9.99 9.99 0 0 1-2.501 4.337 3 3 0 0 0-4.631 2.674 9.99 9.99 0 0 1-5.007-.002 3 3 0 0 0-4.632-2.672A10.018 10.018 0 0 1 3.34 17zm5.66.196a4.993 4.993 0 0 1 2.25 2.77c.499.047 1 .048 1.499.001A4.993 4.993 0 0 1 15 17.197a4.993 4.993 0 0 1 3.525-.565c.29-.408.54-.843.748-1.298A4.993 4.993 0 0 1 18 12c0-1.26.47-2.437 1.273-3.334a8.126 8.126 0 0 0-.75-1.298A4.993 4.993 0 0 1 15 6.804a4.993 4.993 0 0 1-2.25-2.77c-.499-.047-1-.048-1.499-.001A4.993 4.993 0 0 1 9 6.803a4.993 4.993 0 0 1-3.525.565 7.99 7.99 0 0 0-.748 1.298A4.993 4.993 0 0 1 6 12c0 1.26-.47 2.437-1.273 3.334a8.126 8.126 0 0 0 .75 1.298A4.993 4.993 0 0 1 9 17.196zM12 15a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"></path>
        </svg> Settings </router-link>
    </li>
  </ul>
</div>


  </div>
</template>

<script>
import clickOutside from '@/directives/clickOutside';
export default {
  name: 'ChainvaultmineSidebar',

  data() {
    return {
      isSideBarOpen: false
    };
  },

  mounted() {
    
  },
  directives: {
    clickOutside,
  },
  methods: {
    hideSideBar() {
      this.isSideBarOpen = false
    },
    toggleSideBar(){
      this.isSideBarOpen = !this.isSideBarOpen
    },
  },
};
</script>