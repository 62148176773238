import axios from 'axios';

export default {
    namespaced: true,
    state: {
        token: null,
        user: null,
        balance: 0,
        power: 0,
        wallet: null,
        requiresTwoFactorAuth: JSON.parse(localStorage.getItem('requiresTwoFactorAuth')) || false,
    },
    mutations: {
        EL_TOKEN(state, token) {
            state.token = token
        },
        EL_USUARIO(state, data) {
            state.user = data
        },
        setBalance(state, newBalance) {
            state.balance = newBalance;
        },
        setPower(state, newPower) {
            state.power = newPower;
        },
        setWallet(state, newWallet) {
            state.wallet = newWallet;
        },
        setRequiresTwoFactorAuth(state, status) {
            state.requiresTwoFactorAuth = status;
            localStorage.setItem('requiresTwoFactorAuth', JSON.stringify(status)); // Almacena el estado en localStorage
        }
    },
    getters: {
        autenticado(state) {
            return state.token && state.user
        },
        usuario(state) {
            return state.user
        },
        balance(state) {
            return state.balance
        },
        power(state) {
            return state.power
        },
        wallet(state) {
            return state.wallet
        },
        requiresTwoFactorAuth(state) { // Agregar este getter
            return state.requiresTwoFactorAuth;
        },
    },
    actions: {
        /* async fetchSaldo({ commit }) {
            try {
                const response = await axios.get(process.env.VUE_APP_API_URL + 'getUserBalance');
                const newBalance = parseFloat(response.data.balance); // Ajusta según la estructura de tu respuesta
                const newPower = response.data.power;
                const newWallet = response.data.wallet.wallet;
                commit('setBalance', newBalance);
                commit('setPower', newPower);
                commit('setWallet', newWallet);
            } catch (error) {
                console.error('Error fetching balance:', error);
            }
        }, */

        async fetchSaldo({ commit }) {
            try {
                const response = await axios.get(process.env.VUE_APP_API_URL + 'getUserBalance');
                // Verificar si la respuesta tiene los datos necesarios
                if (response.data) {
                    const newBalance = parseFloat(response.data.balance); // Asegura que el balance es un número
                    const newPower = response.data.power;
                    const newWallet = response.data.wallet?.wallet; // Maneja el caso si no hay una wallet disponible

                    // Hacer commit solo si los datos existen
                    if (newBalance !== undefined) {
                        commit('setBalance', newBalance);
                    }
                    if (newPower !== undefined) {
                        commit('setPower', newPower);
                    }
                    if (newWallet !== undefined) {
                        commit('setWallet', newWallet);
                    }
                }
            } catch (error) {
                console.error('Error fetching balance:', error);
            }
        },
        async signIn({ dispatch, commit }, credenciales) {
            let res = await axios.post(process.env.VUE_APP_API_URL + 'login', credenciales);
            await dispatch('intento', res.data.token);

            // Verificar si el usuario tiene habilitado el 2FA
            if (res.data.twoFactorEnabled) {
                commit('setRequiresTwoFactorAuth', true); // Actualizar el estado 2FA
                return { twoFactorEnabled: true };
            } else {
                commit('setRequiresTwoFactorAuth', false); // Actualizar el estado 2FA
                return { twoFactorEnabled: false };
            }
        },
        async intento({ commit, state }, token) {
            if (token) {
                commit('EL_TOKEN', token)
            }

            if (!state.token) {
                return
            }

            try {
                let res = await axios.get(process.env.VUE_APP_API_URL + 'authData')
                commit('EL_USUARIO', res.data)
            } catch (e) {
                commit('EL_USUARIO', null)
                commit('EL_TOKEN', null)
            }
        },
        async logout(context) {
            try {
                /* if (!skipServerLogout) {
                    await axios.post(process.env.VUE_APP_API_URL + 'logout');
                } */

                localStorage.removeItem('token');
                axios.defaults.headers.common['Authorization'] = null;  // Limpia el header de Axios
                context.commit('EL_USUARIO', null);
                context.commit('EL_TOKEN', null);

            } catch (error) {
                // Si hay un error, podemos ignorarlo si es porque el token expiró
                console.error('Error during logout:', 'error');
            } finally {
                // Limpia el estado local independientemente del resultado de la solicitud al servidor
                localStorage.removeItem('token');
                axios.defaults.headers.common['Authorization'] = null;  // Limpia el header de Axios
                context.commit('EL_USUARIO', null);
                context.commit('EL_TOKEN', null);
                //this.$router.push({ name: "login" });
            }
        },
        async verifyTwoFactorCode({ commit }, code) {
            try {
                let res = await axios.post(process.env.VUE_APP_API_URL + 'twoFactorLogin', code);
                if (res.data.success) {
                    commit('setRequiresTwoFactorAuth', false); // Marcar 2FA como completado
                } else {
                    throw new Error(res.data.error);
                }
                //localStorage.removeItem('token');
                //await dispatch('attempt', res.data.token);
                //commit('setRequiresTwoFactorAuth', false);
            } catch (error) {
                throw new Error('Invalid verification code');
            }
        },
        /* async logout(context) {
            let res = await axios.post(process.env.VUE_APP_API_URL + 'logout');
            localStorage.removeItem('token');
            axios.defaults.headers.common['Authorization'] = null;  // Limpia el header de Axios
            context.commit('EL_USUARIO', null);
            context.commit('EL_TOKEN', null);
            return res.data
        }, */
    }
}