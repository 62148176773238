<template>
  <footer class="absolute inset-x-0 bottom-0 hidden bg-white border-t md:block border-gray-light">
        <div class="px-2 py-6 mx-auto text-center 2xl:container"><router-link to="/" class="inline-block mb-2"><img src="@/assets/images/web-logo.svg" width="145" height="24" alt="Logo"></router-link>
          
          <ul class="max-w-md mx-auto text-sm leading-loose text-gray">
            <li class="inline-block mx-1.5">
              <router-link class="hover:text-black" to="/">Home</router-link>
            </li>
            <li class="inline-block mx-1.5">
              <router-link class="hover:text-black" to="/pricing">Pricing</router-link>
            </li>
            <li class="inline-block mx-1.5">
              <router-link class="hover:text-black" to="/insights">Insights</router-link>
            </li>
            <li class="inline-block mx-1.5">
              <router-link class="hover:text-black" to="/about">About</router-link>
            </li>
            <li class="inline-block mx-1.5">
              <router-link class="hover:text-black" to="/faq">FAQs</router-link>
            </li>
            <li class="inline-block mx-1.5">
              <router-link class="hover:text-black" to="/affiliate">Affiliate</router-link>
            </li>
            <li class="inline-block mx-1.5">
              <router-link class="hover:text-black" to="/terms">Terms of Service</router-link>
            </li>
            <li class="inline-block mx-1.5" v-if="!autenticado">
              <router-link class="hover:text-black" to="/login">Sign in</router-link>
            </li>
            <li class="inline-block mx-1.5" v-if="!autenticado">
              <router-link class="hover:text-black" to="/forgot-password">Forgot password?</router-link>
            </li>
            <li class="inline-block mx-1.5" v-if="!autenticado">
              <router-link class="hover:text-black" to="/register">Sign up</router-link>
            </li>
            <li class="inline-block mx-1.5" v-if="autenticado">
              <router-link class="hover:text-black" to="/dashboard">Dashboard</router-link>
            </li>
          </ul>

            <p class="mt-4 text-xs text-black">Copyright © 2021-2024 ChainMine</p>
        </div>
    </footer>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'ChainvaultmineFooter',

  data() {
    return {
      
    };
  },
  computed: {
      ...mapGetters({
          autenticado: 'user/autenticado'
      }),
  },
  mounted() {
    
  },

  methods: {
    
  },
};
</script>