<template>
  <div>
    <Header/>
  
  <div class="flex flex-row w-full account-menu">
    <Sidebar/>
    <div v-if="showToast" class="p-4 text-sm text-gray-800 rounded-lg bg-gray-50 dark:bg-gray-800 dark:text-gray-300 notification" role="alert">
    <template v-if="errors">
      <ul>
        <template v-for="(fieldErrors, field) in errors">
          <li v-for="error in fieldErrors" :key="error">{{ field }}: {{ error }}</li>
        </template>
      </ul>
    </template>
    <template v-if="message !== ''">
      <p>{{message}}</p>
    </template>
  </div>

    <div class="flex-1 overflow-y-auto">
  <div class="px-4 py-4 mx-auto xl:container md:py-14 md:px-8">
    <h1 class="pb-4 mb-8 text-3xl font-bold leading-none text-black border-b-2 border-gray-light max-md:pl-12">Settings</h1>
    <form @submit.prevent="changePassword">
      <h2 class="mb-4 text-xl font-medium text-black">Password settings</h2>
      <div class="grid items-center gap-4 pl-4 mb-8 md:grid-cols-2">
        <div class="flex items-center">
          <svg xmlns="http://www.w3.org/2000/svg" class="mr-4 text-green" viewBox="0 0 24 24" width="24" height="24">
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path fill="currentColor" d="M12.917 13A6.002 6.002 0 0 1 1 12a6 6 0 0 1 11.917-1H23v2h-2v4h-2v-4h-2v4h-2v-4h-2.083zM7 16a4 4 0 1 0 0-8 4 4 0 0 0 0 8z"></path>
          </svg>
          <label class="ml-1 text-sm font-medium leading-none text-black">Current password</label>
        </div>
        <div>
          <input class="text-black block bg-white shadow-md rounded px-3 py-1.5 w-full max-w-xs ml-auto placeholder-green" v-model="form.current" type="password" placeholder="Enter current password" spellcheck="false" autocomplete="current-password">
        </div>
        <div class="flex items-center">
          <svg xmlns="http://www.w3.org/2000/svg" class="mr-4 text-green" viewBox="0 0 24 24" width="24" height="24">
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path fill="currentColor" d="M12.917 13A6.002 6.002 0 0 1 1 12a6 6 0 0 1 11.917-1H23v2h-2v4h-2v-4h-2v4h-2v-4h-2.083zM7 16a4 4 0 1 0 0-8 4 4 0 0 0 0 8z"></path>
          </svg>
          <label class="ml-1 text-sm font-medium leading-none text-black">New password</label>
        </div>
        <div>
          <input class="text-black block bg-white shadow-md rounded px-3 py-1.5 w-full max-w-xs ml-auto placeholder-green" v-model="form.password" type="password" placeholder="Enter new password" spellcheck="false" autocomplete="current-password">
        </div>
        <div class="flex items-center">
          <svg xmlns="http://www.w3.org/2000/svg" class="mr-4 text-green" viewBox="0 0 24 24" width="24" height="24">
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path fill="currentColor" d="M12.917 13A6.002 6.002 0 0 1 1 12a6 6 0 0 1 11.917-1H23v2h-2v4h-2v-4h-2v4h-2v-4h-2.083zM7 16a4 4 0 1 0 0-8 4 4 0 0 0 0 8z"></path>
          </svg>
          <label class="ml-1 text-sm font-medium leading-none text-black">Confirm new password</label>
        </div>
        <div>
          <input class="text-black block bg-white shadow-md rounded px-3 py-1.5 w-full max-w-xs ml-auto placeholder-green" type="password" v-model="form.password_confirmation" placeholder="Confirm new password" spellcheck="false" autocomplete="current-password">
        </div>
        
      </div>
      <div class="text-right">
          <button 
          class="mt-6 ml-auto submit bg-black text-white hover:bg-black-dark rounded py-1.5 px-5" type="submit"
          :disabled="isSubmittingPw"
          >
            <svg 
            v-if="isSubmittingPw"
            class="loader animate-spin text-yellow on" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="22" height="22"><path fill="none" d="M0 0h24v24H0z"></path><path fill="currentColor" d="M12 2a1 1 0 0 1 1 1v3a1 1 0 0 1-2 0V3a1 1 0 0 1 1-1zm0 15a1 1 0 0 1 1 1v3a1 1 0 0 1-2 0v-3a1 1 0 0 1 1-1zm8.66-10a1 1 0 0 1-.366 1.366l-2.598 1.5a1 1 0 1 1-1-1.732l2.598-1.5A1 1 0 0 1 20.66 7zM7.67 14.5a1 1 0 0 1-.366 1.366l-2.598 1.5a1 1 0 1 1-1-1.732l2.598-1.5a1 1 0 0 1 1.366.366zM20.66 17a1 1 0 0 1-1.366.366l-2.598-1.5a1 1 0 0 1 1-1.732l2.598 1.5A1 1 0 0 1 20.66 17zM7.67 9.5a1 1 0 0 1-1.366.366l-2.598-1.5a1 1 0 1 1 1-1.732l2.598 1.5A1 1 0 0 1 7.67 9.5z"></path></svg>
            <div class="inline-flex items-center justify-center"> Save <svg xmlns="http://www.w3.org/2000/svg" class="ml-1 text-yellow" viewBox="0 0 24 24" width="22" height="22">
                <path fill="none" d="M0 0h24v24H0z"></path>
                <path fill="currentColor" d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z"></path>
              </svg>
            </div>
          </button>
        </div>
      </form>
      <h2 class="mb-4 text-xl font-medium text-black">Email settings</h2>
      <div class="grid items-center gap-4 pl-4 mb-8 md:grid-cols-2">
        <div class="flex items-center">
          <svg xmlns="http://www.w3.org/2000/svg" class="mr-4 text-green" viewBox="0 0 24 24" width="24" height="24">
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path fill="currentColor" d="M3 3h18a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm17 4.238l-7.928 7.1L4 7.216V19h16V7.238zM4.511 5l7.55 6.662L19.502 5H4.511z"></path>
          </svg>
          <label class="ml-1 text-sm font-medium leading-none text-black">Email address</label>
        </div>
        <div>
          <input class="text-black block bg-white shadow-md rounded px-3 py-1.5 w-full max-w-xs ml-auto placeholder-green" type="email" name="email" :value="user?.user?.email" placeholder="Email address" spellcheck="false" autocomplete="email" readonly>
        </div>
      </div>
      <form @submit.prevent="changeWallet">
      <h2 class="mb-4 text-xl font-medium text-black">Payout settings</h2>
      <div class="grid items-center gap-4 pl-4 mb-8 md:grid-cols-2">
        <div class="flex items-center">
          <svg xmlns="http://www.w3.org/2000/svg" class="mr-4 text-green" viewBox="0 0 24 24" width="24" height="24">
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path fill="currentColor" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-4H8V8h3V6h2v2h1a2.5 2.5 0 0 1 2 4 2.5 2.5 0 0 1-2 4h-1v2h-2v-2zm-1-3v1h4a.5.5 0 1 0 0-1h-4zm0-3v1h4a.5.5 0 1 0 0-1h-4z"></path>
          </svg>
          <label class="ml-1 text-sm font-medium leading-none text-black">Bitcoin address</label>
        </div>
        <div>
          <input class="text-black block bg-white shadow-md rounded px-3 py-1.5 w-full max-w-xs ml-auto placeholder-green" 
          v-model="bitcoinAddress"
          type="text" name="bitcoin_address" placeholder="Add BTC Wallet for withdrawals" spellcheck="false" autocomplete="off">
          <p v-if="!isValidBitcoinAddress" class="mt-2 px-3 py-1.5 w-full max-w-xs ml-auto text-sm text-red-600 dark:text-red-500"><span class="font-medium">Oops!</span> Please enter a valid Bitcoin address.</p>
        </div>
        <div></div>
        <div class="text-right">
          <button 
          class="mt-6 ml-auto submit bg-black text-white hover:bg-black-dark rounded py-1.5 px-5" type="submit"
          :disabled="isSubmittingWallet"
          >
            <svg 
            v-if="isSubmittingWallet"
            class="loader animate-spin text-yellow on" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="22" height="22"><path fill="none" d="M0 0h24v24H0z"></path><path fill="currentColor" d="M12 2a1 1 0 0 1 1 1v3a1 1 0 0 1-2 0V3a1 1 0 0 1 1-1zm0 15a1 1 0 0 1 1 1v3a1 1 0 0 1-2 0v-3a1 1 0 0 1 1-1zm8.66-10a1 1 0 0 1-.366 1.366l-2.598 1.5a1 1 0 1 1-1-1.732l2.598-1.5A1 1 0 0 1 20.66 7zM7.67 14.5a1 1 0 0 1-.366 1.366l-2.598 1.5a1 1 0 1 1-1-1.732l2.598-1.5a1 1 0 0 1 1.366.366zM20.66 17a1 1 0 0 1-1.366.366l-2.598-1.5a1 1 0 0 1 1-1.732l2.598 1.5A1 1 0 0 1 20.66 17zM7.67 9.5a1 1 0 0 1-1.366.366l-2.598-1.5a1 1 0 1 1 1-1.732l2.598 1.5A1 1 0 0 1 7.67 9.5z"></path></svg>
            <div class="inline-flex items-center justify-center"> Save <svg xmlns="http://www.w3.org/2000/svg" class="ml-1 text-yellow" viewBox="0 0 24 24" width="22" height="22">
                <path fill="none" d="M0 0h24v24H0z"></path>
                <path fill="currentColor" d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z"></path>
              </svg>
            </div>
          </button>
        </div>
      </div>
    </form>
    <h2 class="mb-6 text-2xl font-bold leading-none text-black">Security settings</h2>
    <h2 class="mb-1.5 text-xl font-medium text-black">Configure two-factor authentication</h2>
    <p class="mb-4 text-sm">Simply download a two-factor authentication app from the <a class="font-bold hover:underline text-green underline-offset-4" href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2" target="_blank" rel="noopener noreferrer">Google Play Store</a> or <a class="font-bold hover:underline text-green underline-offset-4" href="https://apps.apple.com/us/app/google-authenticator/id388497605" target="_blank" rel="noopener noreferrer">Apple App Store</a> to get started. </p>
    <template v-if="!user?.user?.google2fa_enable">
    <form class="grid items-center gap-4 pl-4 mb-8 md:grid-cols-2" @submit.prevent="enableTwoFactor">
      <div class="flex items-center">
        <svg xmlns="http://www.w3.org/2000/svg" class="mr-4 text-green" viewBox="0 0 24 24" width="24" height="24">
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path fill="currentColor" d="M7 4v16h10V4H7zM6 2h12a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1zm6 15a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"></path>
        </svg>
        <label class="ml-1 text-sm font-medium leading-none text-black">Add secret to App</label>
      </div>
      <div class="text-right">
        <div id="qrout" class="inline-block p-4 bg-white rounded shadow">
          <qrcode-vue :value="qrCode" size="200" level="H"></qrcode-vue>
        </div>
        <input class="text-black block bg-white shadow-md rounded px-3 py-1.5 w-full max-w-xs ml-auto placeholder-green" readonly type="text" name="otp_secret" :value="user?.user?.google2fa_secret">
      </div>
      <div class="flex items-center">
        <svg xmlns="http://www.w3.org/2000/svg" class="mr-4 text-green" viewBox="0 0 24 24" width="24" height="24">
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path fill="currentColor" d="M4 5v14h16V5H4zM3 3h18a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm3 4h2v2H6V7zm0 4h2v2H6v-2zm0 4h12v2H6v-2zm5-4h2v2h-2v-2zm0-4h2v2h-2V7zm5 0h2v2h-2V7zm0 4h2v2h-2v-2z"></path>
        </svg>
        <label class="ml-1 text-sm font-medium leading-none text-black">Enter your 2FA code</label>
      </div>
      <div>
        <input 
        @keypress="validateOtpCode"
        class="text-black block bg-white shadow-md rounded px-3 py-1.5 w-full max-w-xs ml-auto placeholder-green" v-model="otpCode" type="text" name="otp_code" pattern="[0-9]{6}" placeholder="6-digit code" spellcheck="false" autocomplete="off" required>
      </div>
      <div></div>
      <div class="text-right">
        <button 
        class="mt-6 ml-auto submit bg-black text-white hover:bg-black-dark rounded py-1.5 px-5" type="submit"
        :disabled="isSubmittingTFA"
          >
            <svg 
            v-if="isSubmittingTFA"
            class="loader animate-spin text-yellow on" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="22" height="22"><path fill="none" d="M0 0h24v24H0z"></path><path fill="currentColor" d="M12 2a1 1 0 0 1 1 1v3a1 1 0 0 1-2 0V3a1 1 0 0 1 1-1zm0 15a1 1 0 0 1 1 1v3a1 1 0 0 1-2 0v-3a1 1 0 0 1 1-1zm8.66-10a1 1 0 0 1-.366 1.366l-2.598 1.5a1 1 0 1 1-1-1.732l2.598-1.5A1 1 0 0 1 20.66 7zM7.67 14.5a1 1 0 0 1-.366 1.366l-2.598 1.5a1 1 0 1 1-1-1.732l2.598-1.5a1 1 0 0 1 1.366.366zM20.66 17a1 1 0 0 1-1.366.366l-2.598-1.5a1 1 0 0 1 1-1.732l2.598 1.5A1 1 0 0 1 20.66 17zM7.67 9.5a1 1 0 0 1-1.366.366l-2.598-1.5a1 1 0 1 1 1-1.732l2.598 1.5A1 1 0 0 1 7.67 9.5z"></path></svg>
          <div class="inline-flex items-center justify-center"> Enable 2FA <svg xmlns="http://www.w3.org/2000/svg" class="ml-1 text-yellow" viewBox="0 0 24 24" width="22" height="22">
              <path fill="none" d="M0 0h24v24H0z"></path>
              <path fill="currentColor" d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z"></path>
            </svg>
          </div>
        </button>
      </div>
    </form>
  </template>
  <template v-else>
    <form class="grid items-center gap-4 pl-4 mb-8 md:grid-cols-2" @submit.prevent="disableTwoFactor">
      <div class="flex items-center">
        <svg xmlns="http://www.w3.org/2000/svg" class="mr-4 text-green" viewBox="0 0 24 24" width="24" height="24">
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path fill="currentColor" d="M4 5v14h16V5H4zM3 3h18a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm3 4h2v2H6V7zm0 4h2v2H6v-2zm0 4h12v2H6v-2zm5-4h2v2h-2v-2zm0-4h2v2h-2V7zm5 0h2v2h-2V7zm0 4h2v2h-2v-2z"></path>
        </svg>
        <label class="ml-1 text-sm font-medium leading-none text-black">Enter your 2FA code</label>
      </div>
      <div>
        <input 
        @keypress="validateOtpCode"
        class="text-black block bg-white shadow-md rounded px-3 py-1.5 w-full max-w-xs ml-auto placeholder-green" v-model="otpCode" type="text" name="otp_code" pattern="[0-9]{6}" placeholder="6-digit code" spellcheck="false" autocomplete="off" required>
      </div>
      <div></div>
      <div class="text-right">
        <button 
        class="mt-6 ml-auto submit bg-black text-white hover:bg-black-dark rounded py-1.5 px-5" type="submit"
        :disabled="isSubmittingTFA"
          >
            <svg 
            v-if="isSubmittingTFA"
            class="loader animate-spin text-yellow on" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="22" height="22"><path fill="none" d="M0 0h24v24H0z"></path><path fill="currentColor" d="M12 2a1 1 0 0 1 1 1v3a1 1 0 0 1-2 0V3a1 1 0 0 1 1-1zm0 15a1 1 0 0 1 1 1v3a1 1 0 0 1-2 0v-3a1 1 0 0 1 1-1zm8.66-10a1 1 0 0 1-.366 1.366l-2.598 1.5a1 1 0 1 1-1-1.732l2.598-1.5A1 1 0 0 1 20.66 7zM7.67 14.5a1 1 0 0 1-.366 1.366l-2.598 1.5a1 1 0 1 1-1-1.732l2.598-1.5a1 1 0 0 1 1.366.366zM20.66 17a1 1 0 0 1-1.366.366l-2.598-1.5a1 1 0 0 1 1-1.732l2.598 1.5A1 1 0 0 1 20.66 17zM7.67 9.5a1 1 0 0 1-1.366.366l-2.598-1.5a1 1 0 1 1 1-1.732l2.598 1.5A1 1 0 0 1 7.67 9.5z"></path></svg>
          <div class="inline-flex items-center justify-center"> Disable 2FA <svg xmlns="http://www.w3.org/2000/svg" class="ml-1 text-yellow" viewBox="0 0 24 24" width="22" height="22">
              <path fill="none" d="M0 0h24v24H0z"></path>
              <path fill="currentColor" d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z"></path>
            </svg>
          </div>
        </button>
      </div>
    </form>
  </template>
    
  </div>
</div>


  </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Sidebar from '@/views/user/Sidebar.vue'
import { mapGetters } from 'vuex';
import QrcodeVue from 'qrcode.vue';
import axios from 'axios';
import { validate } from 'bitcoin-address-validation';
export default {
  name: 'ChainvaultmineSettingsView',
  components: {
    Header, Sidebar, QrcodeVue
  },
  data() {
    return {
      isValidBitcoinAddress: true,
      qrCode: '',
      privateCode: '',
      isSubmittingPw: false,
      isSubmittingWallet: false,
      isSubmittingTFA: false,
      showToast: false,
      message: '',
      errors: {},
      form: {
          current: '',
          password: '',
          password_confirmation: ''
      },
      bitcoinAddress: '',
      otpCode: ''
    };
  },
  computed: {
      ...mapGetters({
          autenticado: 'user/autenticado',
          user: 'user/usuario',
          wallet: 'user/wallet',
      }),
  },
  mounted() {
    this.getTwoGoogleCode()
    this.bitcoinAddress = this.wallet || '';
  },

  methods: {
    disableTwoFactor() {
      if (this.isSubmittingTFA) return;

      // Inicia la animación del botón y deshabilítalo
      this.isSubmittingTFA = true;
      if(this.otpCode === ''){
        this.message = 'Invalid Code'
            this.showToast = true;
            this.isSubmittingTFA = false;
            setTimeout(()=>{
              this.showToast = false;
              this.message = '';
            },5000);
            return;
      }
      axios.post(process.env.VUE_APP_API_URL + 'disableTwoFactor', {
        verifyCode: this.otpCode
      })
      .then((res)=>{
        this.showToast = true;
        this.message = res.data.message;
        this.otpCode = '';
        this.isSubmittingTFA = false;
        this.$store.dispatch('user/intento');
        this.$store.dispatch('user/fetchSaldo');
        this.getTwoGoogleCode()
        setTimeout(()=>{
          this.showToast = false;
          this.message = '';
        },5000);
        //console.log(res.data);
      })
      .catch((error)=>{
        if (error.response.status === 422) {
            this.message = error.response.data.error;
            this.showToast = true;
            this.isSubmittingTFA = false;
            setTimeout(()=>{
              this.showToast = false;
              this.message = '';
            },5000);
          
        }
        //console.log(error.response.data.error);
      })
    },
    enableTwoFactor() {
      if (this.isSubmittingTFA) return;

      // Inicia la animación del botón y deshabilítalo
      this.isSubmittingTFA = true;
      if(this.otpCode === ''){
            this.message = 'Invalid Code'
            this.showToast = true;
            this.isSubmittingTFA = false;
            setTimeout(()=>{
              this.showToast = false;
              this.message = '';
            },5000);
            return;
      }
      axios.post(process.env.VUE_APP_API_URL + 'enableTwoFactor', {
        verifyCode: this.otpCode
      })
      .then((res)=>{
        this.showToast = true;
        this.message = res.data.message;
        this.otpCode = '';
        this.isSubmittingTFA = false;
        this.$store.dispatch('user/intento');
        this.$store.dispatch('user/fetchSaldo');
        setTimeout(()=>{
          this.showToast = false;
          this.message = '';
        },5000);
        //console.log(res.data);
      })
      .catch((error)=>{
        if (error.response.status === 422) {
            this.message = error.response.data.error;
            this.showToast = true;
            this.isSubmittingTFA = false;
            setTimeout(()=>{
              this.showToast = false;
              this.message = '';
            },5000);
          
        }
        
      })
    },
    validateOtpCode(event) {
      // Obtener el valor del input
      //const value = event.target.value;
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);

        // Permitir solo números y el punto decimal
        if (!/^[0-9]+$/.test(keyValue) || this.otpCode.length >= 6) {
            event.preventDefault();
        }
    },
    changeWallet () {
      // Evita el envío si el formulario ya se está enviando
      if (this.isSubmittingWallet) return;

      // Inicia la animación del botón y deshabilítalo
      this.isSubmittingWallet = true;

      axios.post(process.env.VUE_APP_API_URL + 'saveWithdrawalWallet', {
        bitcoinAddress: this.bitcoinAddress,
      })
      .then(response => {
        this.showToast = true;
        this.message = response.data.message;
        this.isSubmittingWallet = false;
        setTimeout(()=>{
          this.showToast = false;
          this.message = '';
        },5000);
      })
      .catch((error)=>{
        if (error.response.status === 422) {
          if (typeof error.response.data.error === 'string') {
            this.message = error.response.data.error;
            this.showToast = true;
            this.isSubmittingWallet = false;
            setTimeout(()=>{
              this.showToast = false;
              this.message = '';
            },5000);
          } else {
            this.errors = error.response.data.error;
            this.showToast = true;
            this.isSubmittingWallet = false;
            setTimeout(()=>{
              this.showToast = false;
              this.errors = {};
            },5000);
          }
        }
      })

    },
    changePassword () {
      // Evita el envío si el formulario ya se está enviando
      if (this.isSubmittingPw) return;

      // Inicia la animación del botón y deshabilítalo
      this.isSubmittingPw = true;

      if(this.form.password !== this.form.password_confirmation){
            this.message = 'Passwords do not match.'
            this.showToast = true;
            this.isSubmittingPw = false;
            setTimeout(()=>{
              this.showToast = false;
              this.message = '';
            },5000);
            return;
      }

      axios.post(process.env.VUE_APP_API_URL + 'changePassword', {
        current: this.form.current,
        password: this.form.password,
        password_confirmation: this.form.password_confirmation,
      })
      .then(response => {
        this.showToast = true;
        this.message = response.data.message;
        this.form.current = '';
        this.form.password = '';
        this.form.password_confirmation = '';
        this.isSubmittingPw = false;
        setTimeout(()=>{
          this.showToast = false;
          this.message = '';
        },5000);
      })
      .catch((error)=>{
        if (error.response.status === 422) {
          if (typeof error.response.data.error === 'string') {
            this.message = error.response.data.error;
            this.showToast = true;
            this.isSubmittingPw = false;
            setTimeout(()=>{
              this.showToast = false;
              this.message = '';
            },5000);
          } else {
            this.errors = error.response.data.error;
            this.showToast = true;
            this.showError = true;
            this.isSubmittingPw = false;
            setTimeout(()=>{
              this.showToast = false;
              this.errors = {};
            },5000);
          }
        }
      })
    },
    getTwoGoogleCode() {
      //this.isLoading = true;
      axios.get(process.env.VUE_APP_API_URL + 'getGoogleCode')
      .then((res)=>{
        this.qrCode = res.data.qrCode;
        this.privateCode = res.data.secret;
        //this.isLoading = false;
      })
      .catch((error)=>{
        console.log(error.response.data.error);
      })
    },
  },
  watch: {
    bitcoinAddress(newValue) {
      // Expresión regular para validar la dirección de Bitcoin
      var valid = validate(newValue);
        if(valid)
          this.isValidBitcoinAddress = true;
        else
          this.isValidBitcoinAddress = false;
    },
    wallet(newWallet) {
      this.bitcoinAddress = newWallet || ''; // Si no hay wallet, asignamos una cadena vacía
    }
  }
};
</script>